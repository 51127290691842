import { Checkbox, Option, Select } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import GradientButton from "../../../components/Form/Button/GradientButtonBig";
import { useTranslation } from "react-i18next";
import ModalChangePlan from "../../../components/Modals/ModalChangePlan";
import AlertCloser from "../../../components/Form/Alert/AlertCloser";
import { useSelector, useDispatch } from "react-redux";
import UserAPI from "../../../services/API/Clients/UserAPI";
import AutosizeInput from "react-input-autosize";
import PortfolioAPI from "../../../services/API/Clients/PortfolioAPI";
import { toast } from "react-toastify";
import { updateMe } from "../../../services/redux/actions/generalActions";
import { Storage } from "../../../services/storage";
import { handleError } from "../../../services/Errors/handleErrors";
import { ConfirmLeaveModal, isDeepEqual, useNavigationBlocker } from "../../../services/SaveUtils";
import { useNavigate } from "react-router-dom";

export default function PortfolioAccount() {
    const { t } = useTranslation();
    const [whichFiligrane, setWhichFiligrane] = React.useState("ezoom");
    const [alreadyImported, setAlreadyImported] = React.useState(false);
    const [openChangePlan, setOpenChangePlan] = React.useState(false);
    const general = useSelector((state: any) => state.general);
    const [myPlan, setMyPlan] = React.useState("USER");
    const [initialDataPortfolio, setInitialDataPortfolio] = React.useState<object | null>(null);
    const [dataPortfolio, setDataPortfolio] = React.useState<{
        actualUrl?: string,
        canContact: string,
        canTestimonial: string
    }>({
        actualUrl: "",
        canContact: "",
        canTestimonial: "",
    });
    const myInputFile = React.useRef<HTMLInputElement>(null);
    const [fileLogo, setFileLogo] = React.useState<File | null>(null);
    const [listDisabled, setListDisabled] = React.useState(false);
    const myId = Storage.getId();

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const response = await UserAPI.me();
            if (response.status === 200 || response.status === 201) {
                setMyPlan(response.body.plan);
                if (response.body.closedDialogs.length === 0 || !response.body.closedDialogs.includes("portfolio_datas")) {
                    setListDisabled(true)
                } else
                    setListDisabled(false)
                const resp = await PortfolioAPI.portfolio(response.body.portfolio.id);
                if (resp.status === 200 || resp.status === 201) {
                    setDataPortfolio({
                        ...(resp.body.path && { actualUrl: resp.body.path }),
                        canContact: resp.body.canContact,
                        canTestimonial: resp.body.canAddTestimonial
                    });
                    setInitialDataPortfolio({
                        ...(resp.body.path && { actualUrl: resp.body.path }),
                        canContact: resp.body.canContact,
                        canTestimonial: resp.body.canAddTestimonial
                    });
                } else {
                    handleError(resp);
                }
            } else {
                handleError(response);
            }
            setWhichFiligrane(general?.me?.defaultWatermark ?? "ezoom")
        }
        fetchData();
    }, [])

    const handleChangeListDisables = async () => {
        const datas = {
            "dialogToClose": "portfolio_datas"
        }
        const response = await UserAPI.setDialogs(general.me.id, datas);
        if (response.status === 200 || response.status === 201) {
            setListDisabled(false)
        } else {
            handleError(response);
        }
    }

    const handleSelectFiligrane = (arg: string | undefined) => {
        if (arg !== undefined)
            setWhichFiligrane(arg);
    };

    const handleSubmit = async () => {
        if (!general || !general.me || !general.me.portfolio) return;
        let success = false
        const datasPorfolio = {
            path: dataPortfolio.actualUrl,
            canContact: dataPortfolio.canContact,
            canAddTestimonial: dataPortfolio.canTestimonial
        }
        const response = await PortfolioAPI.putPortfolio(general.me.portfolio.id, datasPorfolio)
        if (response.status === 200 || response.status === 201) {
            success = true
            const responseMe = await UserAPI.me()
            if (responseMe.status === 200) dispatch(updateMe(responseMe.body))
        } else if (response.status === 409) {
            toast.error(t("portfolio_already_exists"));
            success = false
        } else {
            handleError(response);
            success = false
        }
        if (fileLogo) {
            if (!myId) return;
            const formData = new FormData();
            formData.append("image", fileLogo);
            const response = await UserAPI.uploadLogo(formData, myId);
            if (response.status === 200 || response.status === 201) {
                if (success)
                    success = true
                const responseMe = await UserAPI.me()
                if (responseMe.status === 200) dispatch(updateMe(responseMe.body))
            } else {
                success = false
                handleError(response);
            }
        }
        const datasMe = { 
            defaultWatermark: whichFiligrane 
        }
        const responseMe = await UserAPI.putUser(datasMe, general.me.id)
        if (responseMe.status === 200 || responseMe.status === 201) {
            if (success)
                success = true
            dispatch(updateMe(responseMe.body))
            setInitialDataPortfolio(dataPortfolio)
        } else {
            success = false
            handleError(responseMe);
        }
        if (success) {
            toast.success(t("portfolio_updated_params"));
        }
    }

    const handleUploadPicture = () => {
        if (myInputFile.current) {
            myInputFile.current.click();
        }
    }

    const handleChangePicture = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFileLogo(e.target.files[0]);
            setAlreadyImported(true);
        }
    }

    const removeUploadPicture = () => {
        setFileLogo(null);
        setAlreadyImported(false);
    }

    const navigate = useNavigate();
    const { modalVisible, handleConfirmLeave, handleCancelLeave } = useNavigationBlocker(
        initialDataPortfolio !== null && !isDeepEqual(dataPortfolio, initialDataPortfolio),
        navigate,
        (a: string, b: string) => false
    );

    return (
        <>
            <ConfirmLeaveModal isVisible={modalVisible} onConfirm={handleConfirmLeave} onCancel={handleCancelLeave} />
            <ModalChangePlan open={openChangePlan} setOpen={setOpenChangePlan} />
            <div className="flex flex-col bg-gray-50 w-full md:px-4 py-4 rounded-lg">
                <AlertCloser message={t("portfolioinfotext")} disabled={listDisabled} handleDisabled={handleChangeListDisables} />
                <p className="mb-3 text-xl font-sans font-extrabold">{t("myportfolio")}</p>

                <p className="mb-2 text-base font-sans font-bold">{t("myportfolio_link")}</p>
                <p className="mb-4 text-sm font-sans">{t("myportfolio_link_desc")}</p>
                
                <p color="black" className="border-b w-fit flex flex-row text-sm">
                    {t("url")}{" "}
                    {(myPlan === "PRO" || myPlan === "PREMIUM") ? (
                        <AutosizeInput
                            value={dataPortfolio.actualUrl}
                            placeholder={t("your_custom_url")}
                            onChange={(e) => setDataPortfolio({...dataPortfolio, actualUrl: e.target.value })}
                            inputStyle={{ color: "gray", backgroundColor: "transparent", fontSize: "0.875rem", fontWeight: 600, border: "none", outline: "none" }}
                        />
                    ) : (
                        <button color="gray" onClick={() => setOpenChangePlan(true)} className="flex flex-row text-sm opacity-50 ml-1 cursor-pointer">
                            {t("your_custom_url")}
                        </button>
                    )}
                </p>
                {!dataPortfolio.actualUrl || dataPortfolio.actualUrl === "" && (
                    <p color="gray" className="mt-2 flex items-center gap-1 font-normal text-xs opacity-50">
                        <FontAwesomeIcon icon="circle-question" className="w-3 h-3 -mt-0.5" />
                        {t("info_no_custom_url")}
                    </p>
                )}

                <hr className="border-gray-300 mt-6" />

                <p className="mt-4 mb-2 text-base font-sans font-bold">{t("noteandopinion")}</p>
                <div className="flex flex-row gap-2 items-start justify-start mb-4">
                    <Checkbox className="bg-white" color={dataPortfolio.canTestimonial !== "none" ? "orange" : "gray"} checked={dataPortfolio.canTestimonial !== "none"} onChange={() => setDataPortfolio({...dataPortfolio, canTestimonial: dataPortfolio.canTestimonial === "none" ? "everyone" : "none"})} />
                    <p className={`text-sm font-sans align-middle flex items-center ${dataPortfolio.canTestimonial !== "none" ? "text-black" : "text-gray-500"}`}>{t("noteandopinion_d")}</p>
                </div>
                <p className={`mb-4 text-sm font-sans ${dataPortfolio.canTestimonial === "none" ? "text-gray-500" : ""}`}>{t("chooseusers_note")}</p>
                <div className="flex flex-col md:flex-row gap-2 items-center justify-start">
                    <div className="w-full md:w-72">
                        <Select className="bg-white" color="orange" label={t("noteandopinion")} value={dataPortfolio.canTestimonial} onChange={(e) => e !== undefined && setDataPortfolio({...dataPortfolio, canTestimonial: e})} disabled={dataPortfolio.canTestimonial === "none"}>
                            <Option value="everyone">{t("everybody")}</Option>
                            <Option value="registered">{t("registered_users")}</Option>
                            <Option value="confirmed">{t("confirmed_users")}</Option>
                            <Option value="photographers">{t("photographs")}</Option>
                            <Option value="subscriber">{t("your_subs")}</Option>
                        </Select>
                    </div>
                    <p className={`w-full text-sm font-sans ${dataPortfolio.canTestimonial !== "none" ? "text-black" : ""}`}>
                        {dataPortfolio.canTestimonial === "everyone" && t("everybody_d_n")}
                        {dataPortfolio.canTestimonial === "registered" && t("registered_users_d_n")}
                        {dataPortfolio.canTestimonial === "confirmed" && t("confirmed_users_d_n")}
                        {dataPortfolio.canTestimonial === "photographers" && t("photographs_d_n")}
                        {dataPortfolio.canTestimonial === "subscriber" && t("your_subs_d_n")}
                    </p>
                </div>
                
                <hr className="border-gray-300 mt-6" />

                <p className="mt-4 mb-2 text-base font-sans font-bold">{t("contact")}</p>
                <p className="mb-4 text-sm font-sans">{t("contact_d")}</p>
                <div className="flex flex-col md:flex-row gap-2 items-center justify-start">
                    <div className="w-full md:w-72">
                        <Select className="bg-white" color="orange" label={t("contact")} value={dataPortfolio.canContact} onChange={(e) => e !== undefined && setDataPortfolio({...dataPortfolio, canContact: e})}>
                            <Option value="everyone">{t("everybody")}</Option>
                            <Option value="registered">{t("registered_users")}</Option>
                            <Option value="confirmed">{t("confirmed_users")}</Option>
                            <Option value="photographers">{t("photographs")}</Option>
                            <Option value="subscriber">{t("your_subs")}</Option>
                        </Select>
                    </div>
                    <p className={`w-full text-sm font-sans`}>
                        {dataPortfolio.canContact === "everyone" && t("everybody_d_c")}
                        {dataPortfolio.canContact === "registered" && t("registered_users_d_c")}
                        {dataPortfolio.canContact === "confirmed" && t("confirmed_users_d_c")}
                        {dataPortfolio.canContact === "photographers" && t("photographs_d_c")}
                        {dataPortfolio.canContact === "subscriber" && t("your_subs_d_c")}
                    </p>
                </div>
                
                <hr className="border-gray-300 mt-6" />

                <p className="mt-4 mb-2 text-base font-sans font-bold">{t("pictures_protection")}</p>
                <p className="mb-4 text-sm font-sans" dangerouslySetInnerHTML={{ __html: t("pictures_protection_desc")}}></p>
                <div className="flex flex-col gap-2 items-start justify-start">
                    <div className="w-56">
                        <Select className="bg-white" color="orange" label={t("watermark")} value={whichFiligrane} onChange={handleSelectFiligrane}>
                            <Option value="ezoom">{t("logoezoom")}</Option>
                            <Option value="logo">{t("mylogo")}</Option>
                        </Select>
                    </div>
                    {whichFiligrane === "logo" && (
                        <>
                            <div className="flex flex-row mt-2 bg-white gap-4 p-2 rounded-lg border">
                                <div className="bg-gray-200 rounded-lg w-14 h-14">
                                    {alreadyImported ? <img src={fileLogo ? URL.createObjectURL(fileLogo) : general?.me?.logo && general.me.logo} className="w-full h-full object-cover object-center rounded" />
                                     : <img src={
                                        general?.me?.logo
                                    } className="w-full h-full object-cover object-center rounded" />}    
                                </div> 
                                <div className="flex flex-col gap-2 pt-1">
                                    <p className="text-sm font-sans">
                                        {!alreadyImported ? general?.me?.logo ? t("yourlogo") : t("nologo") : t("yourlogo")}
                                    </p>
                                    <div className="flex flex-row gap-2 items-center justify-center">
                                        <input type="file" className="hidden" ref={myInputFile} onChange={handleChangePicture} />
                                        <button className="px-2 py-1 bg-white border w-fit rounded-full text-xs" onClick={() => handleUploadPicture()}>
                                            {!alreadyImported ? general?.me?.logo ? t("change") : t("import") : t("change") }
                                        </button>
                                        {alreadyImported && <FontAwesomeIcon icon="x" className="w-3 h-3 text-gray-700 cursor-pointer" onClick={() => removeUploadPicture()} />}
                                    </div>
                                </div>
                            </div>
                            <p color="gray" className="flex items-center gap-1 font-normal text-xs opacity-50">
                                <FontAwesomeIcon icon="circle-question" className="w-3 h-3 -mt-0.5" />
                                {t("logo_sugg")}
                            </p>
                        </>
                    )}
                    <button className="w-fit mx-auto mt-8" onClick={handleSubmit}>
                        <GradientButton text={t("savechanges")} />
                    </button>
                </div>
            </div>
        </>
    );
}
