import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer/Footer";
import Loader from "../../../components/Loader";
import HeaderForAnimation from "../../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../../components/NavBar/HeaderMobile";
import {
  PortfolioDrawerForFilters,
  PortfolioResult,
} from "../../../components/SearchResult/impl/PortfolioResult";
import MobileFilterButton from "../../../components/SearchResult/mobile/MobileFilterButton";
import TitlePageNoMaxSize from "../../../components/TitlePageNoMaxSize";
import WrapperGrayZone from "../../../components/WrapperGrayZone";
import SearchResult from "../../../components/SearchResult/SearchResult";

export default function Photographers() {
  const { t } = useTranslation();
  document.title = `ezoom | ${t("photographs")}`;
  const [loading, setLoading] = React.useState<boolean>(true);
  const [searchBar, setSearchBar] = React.useState(true);
  const [hidden, setHidden] = React.useState(false);

  const { scrollY } = useScroll();
  useMotionValueEvent(scrollY, "change", (latest: number) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 relative">
      <Loader loading={loading} />
      <PortfolioDrawerForFilters />
      <HeaderForAnimation />
      <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
      <PortfolioResult
        overrides={{
          pagetype: "recent",
        }}
        defaultSort="recent"
      >
        <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-0">
          <motion.div
            variants={{
              visible: { top: "48px", paddingTop: "24px" },
              hidden: { top: "0px", paddingTop: "6px" },
              barsInactive: { top: "48px", paddingTop: "22px" },
              barsActive: { top: "102px", paddingTop: "18px" },
            }}
            animate={
              hidden
                ? searchBar
                  ? "barsInactive"
                  : "hidden"
                : searchBar
                ? "barsActive"
                : "visible"
            }
            transition={{
              duration: 0.35,
              ease: "easeInOut",
            }}
            className="flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pt-6 pb-2 md:static bg-white z-30 border-b border-gray-200"
          >
            <TitlePageNoMaxSize mt={0} mb={0}>
              <FontAwesomeIcon icon="users" className="text-purple-500 mr-2" />
              {window.innerWidth < 768
                ? t("recentsPhotographersSmall")
                : t("recentsPhotographers")}
            </TitlePageNoMaxSize>
            <div className="flex md:hidden flex-row items-center justify-end gap-2 w-1/2 md:mt-4 pr-2">
              <MobileFilterButton />
            </div>
          </motion.div>
          <WrapperGrayZone pt="4" pb="32" px="4">
            <SearchResult />
          </WrapperGrayZone>
        </div>
      </PortfolioResult>
      <Footer />
    </div>
  );
}
