import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import PortfolioAPI from "../../services/API/Clients/PortfolioAPI";
import { getPageName } from "../../services/Search/PhotoSearchUtils";
import { getPageName as getPhotographerPageName } from "../../services/Search/PhotographerSearchUtils";
import { RootState } from "../../services/redux/store";
import { GeneralReducerType } from "../Interfaces/GeneralReducerType";
import { SearchAutocomplete } from "../Interfaces/PortfolioType";
import Suggestions from "./composants/Suggestions";
import { handleError } from "../../services/Errors/handleErrors";
import GalleryAPI from "../../services/API/Clients/GalleryAPI";

function selectOptionSize(option: "photo" | "photographer" | null) {
    if (option === "photographer") return "100%";
    return option === "photo" ? "0px" : "calc(50% + 20px)";
}

function selectOptionSizeReverse(option: "photo" | "photographer" | null) {
    if (option === "photo") return "100%";
    return option === "photographer" ? "0px" : "calc(50% + 20px)";
}

interface SearchContext {
    redirection: string;
    chip: {
        text: string;
        icon: IconProp;
    } | null;
}

interface SearchData {
    focused: "photo" | "photographer" | null;
    typing: boolean;
    textPhoto: string;
    textPhotographer: string;
    photoContext: SearchContext,
    photographerContext: SearchContext,
}

const DEFAULT_SEARCH: SearchData = {
    focused: null,
    typing: false,
    textPhoto: "",
    textPhotographer: "",
    photoContext: {
        redirection: "/search",
        chip: null
    },
    photographerContext: {
        redirection: "/photographers",
        chip: null
    }
}

interface AutocompleteData {
    data: SearchAutocomplete;
    open: boolean;
}

type PagesConfigType = { [key: string]: (segments: string[], search: string) => Partial<SearchData> | null }

const PAGES = (general: GeneralReducerType): PagesConfigType => ({
    "recent": (segments, search) => {
        if (segments.length !== 1) return null;
        return {
            focused: "photo",
            textPhoto: search,
            photoContext: {
                redirection: "/recent",
                chip: {
                    text: "recents",
                    icon: "clock"
                }
            }
        }
    },
    "popular": (segments, search) => {
        if (segments.length !== 1) return null;
        return {
            focused: "photo",
            textPhoto: search,
            photoContext: {
                redirection: "/popular",
                chip: {
                    text: "populars",
                    icon: "fire"
                }
            }
        }
    },
    "search": (segments, search) => {
        return {
            focused: "photo",
            textPhoto: search,
            photoContext: {
                redirection: segments.length === 3 ? '/' + segments.join("/") : "/search",
                chip: segments.length === 3 ? {
                    text: getPageName(general, segments[1], segments[2]) ?? segments[2],
                    icon: "search"
                } : null
            }
        }
    },
    "galeries": (segments, search) => {
        if (segments.length !== 1) return null;
        return {
            focused: "photo",
            textPhoto: search,
            photoContext: {
                redirection: "/galeries",
                chip: {
                    text: "galeries",
                    icon: "images"
                }
            }
        }
    },
    "photographers": (segments, search) => {
        return {
            focused: "photographer",
            textPhotographer: search,
            photographerContext: {
                redirection: segments.length === 2 ? '/' + segments.join("/") : "/photographers",
                chip: segments.length === 3 ? {
                    text: getPhotographerPageName(general, segments[1], segments[2]) ?? segments[2],
                    icon: "users"
                } : null
            }
        }
    },
    "popular-photographers": (segments, search) => {
        if (segments.length !== 1) return null;
        return {
            focused: "photographer",
            textPhotographer: search,
            photographerContext: {
                redirection: "/popular-photographers",
                chip: {
                    text: "populars",
                    icon: "fire"
                }
            }
        }
    },
    "recent-photographers": (segments, search) => {
        if (segments.length !== 1) return null;
        return {
            focused: "photographer",
            textPhotographer: search,
            photographerContext: {
                redirection: "/recent-photographers",
                chip: {
                    text: "recentsphotographers",
                    icon: "clock"
                }
            }
        }
    }
});

interface DoubleBarProps {
    portfolio?: {
        name: string;
        path: string;
    },
    gallery?: {
        name: string;
    },
    amIOnHomePage?: boolean;
}

const getSearchContext = (searchQuery: string | null, general: GeneralReducerType, route: string, props: DoubleBarProps): Partial<SearchData> => {
    const path = route.split("?")[0];
    const pathArray = path.split("/").filter((item) => item.length > 0);
    if (pathArray.length === 0) return {};

    const page = pathArray[0];

    const pageBuilder = PAGES(general);
    if (props.portfolio) {
        return {
            focused: "photo",
            textPhoto: searchQuery ?? "",
            photoContext: {
                redirection: `/${props.portfolio.path}/photos`,
                chip: {
                    text: props.portfolio.name,
                    icon: "images"
                }
            }
        }
    }

    if (props.gallery) {
        return {
            focused: "photo",
            textPhoto: searchQuery ?? "",
            photoContext: {
                redirection: pathArray.length === 2 ? '/' + pathArray.join("/") : "/galeries",
                chip: pathArray.length === 2 ? {
                    text: props.gallery.name,
                    icon: "images"
                } : null
            }
        }
    }

    if (Object.keys(pageBuilder).includes(page)) return pageBuilder[page as keyof typeof pageBuilder](pathArray, searchQuery ?? "") ?? {};

    return {}
}

export default function DoubleBar(props: DoubleBarProps) {
    const navigate = useNavigate();

    const { t } = useTranslation();
    const { pathname, search } = useLocation();

    const query = new URLSearchParams(search);
    const queryParam = query.get("query");

    const general = useSelector((state: RootState) => state.general);

    const [selectSearch, setSelectSearch] = React.useState<SearchData>({
        ...DEFAULT_SEARCH,
        ...getSearchContext(queryParam, general, pathname, props)
    });

    useEffect(() => {
        setSelectSearch({
            ...selectSearch,
            ...getSearchContext(queryParam, general, pathname, props)
        });
    }, [pathname, queryParam, props]);

    const [autocomplete, setAutocomplete] = React.useState<AutocompleteData>({
        data: {
            portfolios: [],
            suggestions: []
        },
        open: false
    });

    useEffect(() => {
        if (selectSearch.focused === "photo") {
            setSelectSearch({ ...selectSearch, textPhoto: queryParam ?? "" })
        }
        if (selectSearch.focused === "photographer") {
            setSelectSearch({ ...selectSearch, textPhotographer: queryParam ?? "" })
        }
    }, [queryParam]);

    useEffect(() => {
        const fetchAutocomplete = async () => {
            if (selectSearch.focused === "photo") {
                if (selectSearch.textPhoto.length === 0) {
                    setAutocomplete({ data: { portfolios: [], suggestions: [] }, open: false });
                    return;
                }
                const response = await PicturesAPI.autocomplete(selectSearch.textPhoto);
                if (response.status === 200) {
                    setAutocomplete({ data: response.body, open: true });
                } else {
                    handleError(response);
                }
            }

            if (selectSearch.focused === "photographer") {
                if (selectSearch.textPhotographer.length === 0) {
                    setAutocomplete({ data: { portfolios: [], suggestions: [] }, open: false });
                    return;
                }
                const response = await PortfolioAPI.autocomplete(selectSearch.textPhotographer);
                if (response.status === 200) {
                    setAutocomplete({ data: response.body, open: true });
                } else {
                    handleError(response);
                }
            }
        }
        fetchAutocomplete();
    }, [selectSearch.textPhoto, selectSearch.textPhotographer]);

    const setFocused = (option: "photo" | "photographer" | null) => {
        setSelectSearch({ ...selectSearch, focused: option, typing: !!option });
    }

    const setBlur = () => {
        setSelectSearch({ ...selectSearch, typing: false });
    }

    const setSearchPhoto = (text: string) => {
        setSelectSearch({ ...selectSearch, textPhoto: text });
    }

    const clearPhotoContext = () => {
        setSelectSearch({ ...selectSearch, photoContext: DEFAULT_SEARCH.photoContext });
    }

    const clearPhotographerContext = () => {
        setSelectSearch({ ...selectSearch, photographerContext: DEFAULT_SEARCH.photographerContext });
    }

    const clearPhotoBar = () => {
        setSelectSearch({ ...selectSearch, textPhoto: "", photoContext: DEFAULT_SEARCH.photoContext, focused: null });
    }

    const clearPhotographerBar = () => {
        setSelectSearch({ ...selectSearch, textPhotographer: "", photographerContext: DEFAULT_SEARCH.photographerContext, focused: null });
    }

    const setSearchPhotographer = (text: string) => {
        setSelectSearch({ ...selectSearch, textPhotographer: text });
    }

    const searchPhotographer = (searchQuery: string) => {
        if (searchQuery.length === 0) {
            navigate(selectSearch.photographerContext.redirection);
        } else {
            navigate(`${selectSearch.photographerContext.redirection}?query=${searchQuery}`);
        }
    }

    const searchPhoto = (searchQuery: string) => {
        if (searchQuery.length === 0) {
            navigate(selectSearch.photoContext.redirection);
        } else {
            navigate(`${selectSearch.photoContext.redirection}?query=${searchQuery}`);
        }
    }

    function useOutsideAlerter(ref: React.RefObject<HTMLElement>) {
        useEffect(() => {
            function handleClickOutside(event: MouseEvent) {
                if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
                    if (selectSearch.focused === "photo" && hasPhotoQuery || selectSearch.focused === "photographer" && hasPhotographerQuery) {
                        setBlur();
                    } else {
                        setFocused(null);
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, selectSearch]);
    }
    const doubleBarRef = React.useRef<HTMLDivElement>(null);

    useOutsideAlerter(doubleBarRef);

    const returnPlaceholderPhotos = () => {
        if (props.amIOnHomePage) {
            if (window.innerWidth < 959) {
                return t("searchbar_photos_placeholder")
            } else if (window.innerWidth < 1035) {
                return t("photos")
            } else if (window.innerWidth < 1280) {
                return t("searchbar_photos_placeholder")
            } else {
                return t("search")
            }
        } else {
            return t("searchbar_photos_placeholder")
        }
    }

    const returnPlacehoderPhotographers = () => {
        if (props.amIOnHomePage) {
            if (window.innerWidth < 959) {
                return t("searchbar_photographer_placeholder")
            } else if (window.innerWidth < 1035) {
                return t("photographs")
            } else if (window.innerWidth < 1280) {
                return t("searchbar_photographer_placeholder")
            } else {
                return t("searchphotographer")
            }
        } else {
            return t("searchbar_photographer_placeholder")
        }
    }

    const hasPhotoQuery = selectSearch.textPhoto.length > 0 || selectSearch.photoContext.chip !== null;
    const hasPhotographerQuery = selectSearch.textPhotographer.length > 0 || selectSearch.photographerContext.chip !== null;

    const openAutocomplete = !!selectSearch.focused && selectSearch.typing && autocomplete.open && (autocomplete.data.suggestions.length > 0 || autocomplete.data.portfolios.length > 0);


    return (
        <div
            className={`flex transition-all relative z-10 opacity-100 ${props.amIOnHomePage ? "w-full lg:w-4/5 my-0 mx-auto max-w-[90vw] lg:max-w-[40vw]" : "my-0 mx-auto lg:w-[40vw] w-full lg:max-w-[40vw]"}`}
            ref={doubleBarRef}
        >
            <div
                className={`transition-all ease-in-out left-0 bg-white h-10 absolute border border-orange-500 ${openAutocomplete && "border-b-0"} ${selectSearch.focused === "photographer" ? "opacity-0" : "inline-block"}`}
                style={{
                    width: `${selectOptionSizeReverse(selectSearch.focused)}`,
                    borderRadius: `${openAutocomplete ? "20px 20px 0px 0px" : "20px"}`,
                }}
            >
                <div className={`h-10 px-2 pr-10 vertical-middle items-center ${selectSearch.focused === "photographer" ? "hidden" : "flex"}`} style={{ width: "calc(100% - 17px)" }}>
                    <FontAwesomeIcon icon="search" className="-mt-1 text-orange-500" />
                    {selectSearch.photoContext.chip && (
                        <div className="flex flex-row items-center -mt-1 text-xs mx-0.5 pl-3 pr-2.5 pb-0.5 pt-1 bg-gray-100 rounded-full text-gray-700 whitespace-nowrap max-w-[50%] text-ellipsis">
                            <FontAwesomeIcon icon={selectSearch.photoContext.chip.icon} className="mr-1 text-[10px]" />
                            <div className="line-clamp-1 text-ellipsis w-full whitespace-nowrap">
                                {t(selectSearch.photoContext.chip.text)}
                            </div>
                            <FontAwesomeIcon
                                icon="x"
                                className="ml-1.5 text-[8px] cursor-pointer"
                                onClick={() => {
                                    clearPhotoContext();
                                }}
                            />
                        </div>
                    )}
                    <input
                        onFocus={() => setFocused("photo")}
                        onChange={(e) => setSearchPhoto(e.target.value)}
                        value={selectSearch.textPhoto}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                searchPhoto(selectSearch.textPhoto);
                            }
                        }}
                        className="mx-auto -mt-1 text-sm text-grey-400 focus:border-0 focus:ring-0 focus:outline-none"
                        placeholder={returnPlaceholderPhotos()}
                        style={{ width: "calc(100% - 30px)" }}
                    />
                    <div>
                        <button
                            className={`${selectSearch.focused === "photo" ? "flex" : "hidden"} absolute top-1/2 -translate-y-1/2 right-8 rounded-full items-center justify-center w-7 h-7`}
                            onClick={() => {
                                clearPhotoBar();
                            }}
                        >
                            <FontAwesomeIcon icon="x" className="text-gray-500 text-xs" />
                        </button>
                        <button
                            className={`${selectSearch.focused === "photo" ? "flex" : "hidden"} absolute top-1/2 -translate-y-1/2 right-1 rounded-full items-center justify-center w-7 h-7 bg-orange-500`}
                            onClick={() => {
                                searchPhoto(selectSearch.textPhoto);
                            }}
                        >
                            <FontAwesomeIcon icon="magnifying-glass" className="text-white text-sm" />
                        </button>
                    </div>
                </div>
            </div>
            <div
                className={`transition-all right-0 bg-white h-10 inline-block absolute border border-purple-500  ${openAutocomplete && "border-b-0"} ${selectSearch.focused === "photo" ? "opacity-0" : "inline-block"}`}
                style={{
                    width: `${selectOptionSize(selectSearch.focused)}`,
                    borderRadius: `${openAutocomplete ? "20px 20px 0px 0px" : "20px"}`,
                }}
            >
                <div className={`h-10 px-2 pr-10 vertical-middle items-center ${selectSearch.focused === "photo" ? "hidden" : "flex"}`} style={{ width: "calc(100% - 17px)" }}>
                    <FontAwesomeIcon icon="search" className="-mt-1 text-purple-500" />
                    {selectSearch.photographerContext.chip && (
                        <div className="flex flex-row items-center -mt-1 text-xs mx-0.5 pl-3 pr-2.5 pb-0.5 pt-1 bg-gray-100 rounded-full text-gray-700 whitespace-nowrap max-w-[50%] text-ellipsis">
                            <FontAwesomeIcon icon={selectSearch.photographerContext.chip.icon} className="mr-1 text-[10px]" />
                            {t(selectSearch.photographerContext.chip.text)}
                            <FontAwesomeIcon
                                icon="x"
                                className="ml-1.5 text-[8px] cursor-pointer"
                                onClick={() => {
                                    clearPhotographerContext();
                                }}
                            />
                        </div>
                    )}
                    <input
                        onFocus={() => setFocused("photographer")}
                        onChange={(e) => setSearchPhotographer(e.target.value)}
                        value={selectSearch.textPhotographer}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                searchPhotographer(selectSearch.textPhotographer);
                            }
                        }}
                        className="mx-auto -mt-1 text-sm text-grey-400 focus:border-0 focus:ring-0 focus:outline-none"
                        placeholder={returnPlacehoderPhotographers()}
                        style={{ width: "calc(100% - 30px)" }}
                    />
                    <div>
                        <button
                            className={`${selectSearch.focused === "photographer" ? "flex" : "hidden"
                                } absolute top-1/2 -translate-y-1/2 right-8 rounded-full items-center justify-center w-7 h-7`}
                            onClick={() => {
                                clearPhotographerBar();
                            }}
                        >
                            <FontAwesomeIcon icon="x" className="text-gray-500 text-xs" />
                        </button>
                        <button
                            className={`${selectSearch.focused === "photographer" ? "flex" : "hidden"
                                } absolute top-1/2 -translate-y-1/2 right-1 rounded-full items-center justify-center w-7 h-7 bg-purple-500`}
                            onClick={() => {
                                searchPhotographer(selectSearch.textPhotographer);
                            }}
                        >
                            <FontAwesomeIcon icon="magnifying-glass" className="text-white text-sm" />
                        </button>
                    </div>
                </div>
            </div>
            <Suggestions
                suggestions={autocomplete.data}
                open={openAutocomplete}
                option={selectSearch.focused === "photo" ? "photo" : "photographer"}
                onSearch={selectSearch.focused === "photo" ? searchPhoto : searchPhotographer}
            />
        </div>
    );
}
