import { Spinner } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useLocation } from "react-router-dom";
import SwipeGalery from "../Box/Gallery/SwipeGalery";
import { SearchGalleriesType } from "../Interfaces/PortfolioType";
import Filters from "./filters/Filters";
import { useFilters } from "./provider/FilterProvider";
import i18next from "i18next";

interface PageSettings<Entity> {
  result: Entity[];
  galleries?: SearchGalleriesType;
  page: number;
  lastPage?: number;

  loading: boolean;
}

const defaultPageSettings: PageSettings<any> = {
  result: [],
  page: 1,
  loading: false,
};

function SearchResult<Entity>({ handleOpenOrganizePictureModal } : { handleOpenOrganizePictureModal?: () => void }) {
  const {
    filterStore,
    filterConfiguration,
    fetchFilters,
    fetchResults,
    fetchGalleries,
    filtersRender,
    filtersSchema,
    sortOptions,
    defaultSort,
    filters,
    renderEntity,
    renderParent,
    onInit,
    onNewResults,
    NoResults,
    hideFilters,
    reloadDate,
  } = useFilters();

  const [page, setPage] = useState<PageSettings<Entity>>(defaultPageSettings);
  const [isInit, setIsInit] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const updatePage = (newPage: Partial<PageSettings<Entity>>) => {
    setPage({ ...page, ...newPage });
  };

  const getResults = async (pageNo: number, loadFilters: boolean = false) => {
    const entities = await fetchResults({
      filters: filterConfiguration,
      page: pageNo,
    });
    if (!entities) return;

    const galleries = {};
    if (pageNo === 1) {
      page.result = [];

      if (fetchGalleries) {
        const galleriesRes = await fetchGalleries({
          filters: filterConfiguration,
          page: pageNo,
        });

        if (galleriesRes) (galleries as any).galleries = galleriesRes;
      }
    }

    updatePage({
      result: [...page.result, ...entities.data],
      lastPage: entities.pagination.last,
      page: pageNo,
      ...galleries,
      loading: false,
    });
  };

  const query = new URLSearchParams(useLocation().search);
  const pageQuery = query.get("query");

  useEffect(() => {
    filterConfiguration.initStore(filtersSchema);
    if (onInit) onInit(() => getResults(page.page + 1));
    setIsInit(true);
  }, [pageQuery]);

  useEffect(() => {
    if (!isInit) return;
    if (!filterStore?.currentFilters) return;
    filterConfiguration.updateUrl();
    getResults(1, true);
    updatePage({
      loading: true,
      result: [],
    });
  }, [filterStore?.currentFilters, filterStore?.sortBy, isInit, reloadDate]);

  const nextPage = () => {
    getResults(page.page + 1);
  };

  useEffect(() => {
    if (onNewResults) onNewResults(page.result);
  }, [page.result]);

  return (
    <div>
      <div className="flex flex-wrap flex-row-reverse justify-start items-center w-full mb-4 gap-2">
        {filters && (page.result.length > 0 || (page.galleries?.pagination?.totalItems && page.galleries?.pagination?.totalItems > 0) ) && (
          <Filters
            filters={filterConfiguration}
            renderConfiguration={filtersRender}
            fetchFilters={fetchFilters}
            sortOptions={sortOptions}
            defaultSort={defaultSort}
            open={filtersOpen}
            setOpen={setFiltersOpen}
            hideFilters={hideFilters}
          />
        )}
        {handleOpenOrganizePictureModal !== undefined && (
          <div className="flex items-center justify-center pr-2 border-r border-r-gray-200">
            <button
              onClick={handleOpenOrganizePictureModal}
              className="hidden md:flex border w-fit hover:text-black px-4 py-1 rounded-full bg-white hover:bg-gray-50 transition-all"
            >
              {i18next.t("organizepictures")}
            </button>
          </div>
        )}
      </div>
      {page.galleries && <SwipeGalery galeries={page.galleries} />}
      {page.result.length !== 0 ? (
        <InfiniteScroll
          loadMore={nextPage}
          hasMore={page.lastPage !== undefined && page.page < page.lastPage}
          loader={
            <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
              <Spinner
                color="orange"
                className="mt-12 text-4xl text-gray-500/50 mb-4"
              />
            </div>
          }
          className={
            renderParent
              ? "w-full pt-4 md:pt-2"
              : "flex flex-wrap justify-center gap-4 md:gap-8 w-full pt-4 md:pt-2"
          }
        >
          {renderEntity &&
            page.result?.map((v, i) =>
              renderEntity(v, i, page.result, nextPage)
            )}
          {renderParent && page.result && renderParent(page.result, nextPage)}
        </InfiniteScroll>
      ) : (
        <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
          {page.loading ? (
            <Spinner
              color="orange"
              className="mt-12 text-4xl text-gray-500/50 mb-4"
            />
          ) : (
            NoResults
          )}
        </div>
      )}
    </div>
  );
}

export default SearchResult;
