import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";

import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import "moment/locale/fr";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import HeaderForAnimation from "../../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../../components/NavBar/HeaderMobile";
import {
  PictureDrawerForFilters,
  PictureResult,
} from "../../../components/SearchResult/impl/PictureResult";
import MobileFilterButton from "../../../components/SearchResult/mobile/MobileFilterButton";
import TitlePageNoMaxSize from "../../../components/TitlePageNoMaxSize";
import WrapperGrayZone from "../../../components/WrapperGrayZone";
import {
  getPageName,
  getPageOverride,
} from "../../../services/Search/PhotoSearchUtils";
import { RootState } from "../../../services/redux/store";
import SearchResult from "../../../components/SearchResult/SearchResult";

export default function Search() {
  const { t } = useTranslation();
  document.title = `ezoom | ${t("photos")}`;
  const [loading, setLoading] = React.useState<boolean>(true);
  const [searchBar, setSearchBar] = React.useState(true);
  const [hidden, setHidden] = React.useState(false);
  const { scrollY } = useScroll();
  const [reloadDate, setReloadDate] = React.useState<Date>(new Date());
  useMotionValueEvent(scrollY, "change", (latest: number) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });

  const general = useSelector((state: RootState) => state.general);

  const { type, value } = useParams();

  useEffect(() => {
    setReloadDate(new Date());
  }, [type, value]);

  const pageName =
    (type && value && getPageName(general, type, value)) ?? t("searching");

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 relative">
      <PictureDrawerForFilters />
      <Loader loading={loading} />
      <HeaderForAnimation />
      <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
      <PictureResult
        overrides={getPageOverride(type, value) as any}
        defaultSort="popular"
        reloadDate={reloadDate}
      >
        <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-0">
          <motion.div
            variants={{
              visible: { top: "48px", paddingTop: "24px" },
              hidden: { top: "0px", paddingTop: "6px" },
              barsInactive: { top: "48px", paddingTop: "22px" },
              barsActive: { top: "102px", paddingTop: "18px" },
            }}
            animate={
              hidden
                ? searchBar
                  ? "barsInactive"
                  : "hidden"
                : searchBar
                  ? "barsActive"
                  : "visible"
            }
            transition={{
              duration: 0.35,
              ease: "easeInOut",
            }}
            className="flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pt-6 pb-2 md:static bg-white z-30 border-b border-gray-200"
          >
            <TitlePageNoMaxSize mt={3} mb={0}>
              <FontAwesomeIcon icon="images" className="text-orange-500 mr-2" />
              {pageName}
            </TitlePageNoMaxSize>
            <div className="flex md:hidden flex-row items-center justify-end gap-2 w-1/2 md:mt-4 pr-2">
              <MobileFilterButton />
            </div>
          </motion.div>
          <WrapperGrayZone pt="4" pb="32" px="4">
            <SearchResult />
          </WrapperGrayZone>
        </div>
      </PictureResult>
      <Footer />
    </div>
  );
}
