import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, IconButton, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CompatibleFilterList, FilterList, FilterRenderConfiguration, FilterRenderer, FilterType } from "../types/global";
import { FilterConfiguration } from "../filters/FilterConfiguration";
import { useNavigate } from "react-router-dom";
import DropDownMobile from "./DropDownMobile";
import BooleanFilterMobile from "./BooleanFilterMobile";

interface DrawerForFilterProps<CompatibleFiltersType extends CompatibleFilterList> {
    renderConfiguration: FilterRenderConfiguration<CompatibleFiltersType>
}

export default function DrawerForFilter<FiltersType extends FilterList, CompatibleFiltersType extends CompatibleFilterList>(props: DrawerForFilterProps<CompatibleFiltersType>) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const filters = useSelector((state: any) => state.filters);
    const navigate = useNavigate();

    const filterConfiguration = new FilterConfiguration<FiltersType>(navigate, filters, dispatch, {} as FiltersType);

    const availableFilters: CompatibleFiltersType = filters.compatibleFilters;
    const currentFilters: FiltersType = filterConfiguration.getAdditionalFilters();

    const closeFilter = () => {
        dispatch({ type: "CLOSE_MOBILE_DRAWER" });
    }

    const addFilter = (filter: string, value: string) => {
        dispatch({ type: "ADD_FILTER", payload: { filter, value } });
    }

    const removeFilter = (filter: string) => {
        dispatch({ type: "REMOVE_FILTER", payload: { filter } });
    }

    const resetFilters = () => {
        dispatch({ type: "CLEAR_FILTERS" });
    }

    const Renderers: { [key in FilterType]: FilterRenderer<CompatibleFiltersType> } = {
        [FilterType.BOOLEAN]: (name, values, selected, configuration) => {
            return (
                <BooleanFilterMobile
                    title={`filters.${name as string}`}
                    removeSelected={(() => filterConfiguration.removeFilter(name as keyof FiltersType))}
                    selected={selected as boolean}
                    setSelected={(() => filterConfiguration.addFilter(name as keyof FiltersType, true))}
                />
            )
        },
        [FilterType.DROPDOWN]: (name, values, selected, configuration) => {
            return (
                <DropDownMobile
                    title={`filters.${name as string}`}
                    list={values.map((v) => ({
                        key: configuration.getKey(v),
                        value: configuration.getValue(v)
                    }))}
                    selected={selected as string}
                    setSelected={((key: string) => {
                        filterConfiguration.addFilter(name as keyof FiltersType, key)
                        if (name === "region") {
                            filterConfiguration.addFilter("country", "CHE");
                        }
                    })}
                    removeSelected={(() => {
                        filterConfiguration.removeFilter(name as keyof FiltersType)
                        if (name === "region") {
                            filterConfiguration.removeFilter("country")
                        }
                    })}
                />
            )
        },
        [FilterType.DATEPICKER]: (values, selected, configuration) => {
            return (
                <div>UNSUPPORTED</div>
            )
        },
        [FilterType.MAPPICKER]: (values, selected, configuration) => {
            return (
                <div>UNSUPPORTED</div>
            )
        },
        [FilterType.CHIPS]: (values, selected, configuration) => {
            return (
                <div>UNSUPPORTED</div>
            )
        }
    };

    return (
        <Drawer open={filters.isMobileDrawerOpen} onClose={closeFilter} className="py-4 px-2 md:hidden w-full flex flex-col justify-start items-center overflow-y-auto">
            <div className="mb-1 flex items-center justify-between w-full pl-3">
                <Typography variant="h5" color="blue-gray">
                    {t("filter")}
                </Typography>
                <FontAwesomeIcon icon="x" className="absolute top-2 right-2 text-gray-500 cursor-pointer" onClick={closeFilter} />
            </div>
            <button className="mx-auto w-fit px-4 py-1.5 rounded-full bg-gray-400 hover:bg-gray-500 transition-all mb-2 text-white" onClick={() => resetFilters()}>
                {t("resetfilters")}
            </button>

            <div className="flex flex-col justify-start items-start w-full">
                {availableFilters && Object.keys(availableFilters).sort(
                    (a, b) => Object.keys(props.renderConfiguration).indexOf(a) - Object.keys(props.renderConfiguration).indexOf(b)
                ).map((key) => {
                    if (!availableFilters)
                        return;
                    const value = availableFilters[key];
                    if (!value || value.length < 2)
                        return;
                    return (
                        props.renderConfiguration[key] && Renderers[props.renderConfiguration[key].mobile](key, value as any, currentFilters[key], props.renderConfiguration[key])
                    )
                })}
            </div>

            <button className="mx-auto w-fit px-4 py-1.5 rounded-full bg-orange-400 hover:bg-orange-700 transition-all mt-2 text-white" onClick={closeFilter}>
                {filters.length === 0 ? t("see_no_picture") : filters.length === 1 ? t("see_picture") : t("see_pictures", { count: filters.length })}
            </button>

        </Drawer>
    );
}
