import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Typography } from "@material-tailwind/react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SubscriptionAPI from "../../../../services/API/Clients/SubscriptionAPI";
import { handleError } from "../../../../services/Errors/handleErrors";

export default function PurchaseSub({
    historyPaymentSub,
}: Readonly<{
    historyPaymentSub: {
        pagination: {
            current: number;
            last: number;
            totalItems: number;
            parPage: number;
        };
        data: {}[];
    };
}>) {
    const { t } = useTranslation();

    const getMyPdf = async (id: string) => {
        const response = await SubscriptionAPI.getPdfSubscription(id);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(response.body);
            window.open(url);
        } else {
            handleError(response);
        }
    };

    return (
        <div className="w-full overflow-x-auto">
            {historyPaymentSub.data.length > 0 ? (
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tl">
                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                    {t("date")}
                                </Typography>
                            </th>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                    {t("time")}
                                </Typography>
                            </th>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                    {t("payement_method")}
                                </Typography>
                            </th>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                    {t("amount")}
                                </Typography>
                            </th>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                    {t("product_period")}
                                </Typography>
                            </th>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                    {t("invoice")}
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {historyPaymentSub.data.map((item: any, index: number) => (
                            <tr className={`${index % 2 ? "bg-blue-gray-50/75" : "bg-white"}  hover:bg-gray-200 transition-all`} key={item.id}>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {moment(item.createdAt).format("DD.MM.YYYY")}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {moment(item.createdAt).format("HH:mm")}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {item.displayText}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {t("chf")} {item.amount}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {t("subscri")} {item.subscriptionPlan}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <IconButton
                                        onClick={() => getMyPdf(item.id)}
                                        variant="outlined"
                                        color="orange"
                                        className="rounded-full  hover:text-orange4500 hover:border-orange-400 mr-4"
                                    >
                                        <FontAwesomeIcon icon="file-pdf" />
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                    <Typography className="text-base text-gray-700 my-4 text-center">{t("noAbo")}</Typography>
                </div>
            )}
        </div>
    );
}
