
import { createBrowserRouter, Navigate } from 'react-router-dom';

import HomeIn from "./Home/LogIn/Home";
import Home from "./Home/LogOut/Home";
import ForgotPassword from "./Signin/ForgotPassword";
import Signin from "./Signin/Signin";
import EmailConfirmation from "./Signup/EmailConfirmation";
import PhoneConfirmation from "./Signup/PhoneConfirmation";
import Signup from "./Signup/Signup";

import Favorites from "./Favorites/Favorites";

import EditGallery from "./Gallery/Edit";
import Gallery from "./Gallery/Gallery";

import EditPortfolio from "./Portfolio/Edit";
import Portfolio from "./Portfolio/Portfolio";

import Photographers from "./Photographers/Photographers";

import Account from "./Account/Account";
import Stats from "./Stats/Stats";
import Subscription from "./Subscription/Subscription";

import Upload from "./Upload/Upload";

import Contact from "./Contact/Contact";

import Page404 from "./Bundle/404/Page404";
import Contracts from "./Contracts/Contracts";
import Download from "./Download/Download";
import About from "./Other/About";
import Avis from "./Other/Avis";
import Cgu from "./Other/CGU";
import Cgv from "./Other/CGV";
import Contacts from "./Other/Contact";
import Privacy from "./Other/Privacy";
import Galeries from "./Research/Galeries/Galeries";
import Popular from "./Research/Popular/Popular";
import PopularPhotographers from "./Research/Popular/PopularPhotographer";
import Recent from "./Research/Recent/Recent";
import Search from "./Research/Search/Search";

import Purchases from "./Purchases/Purchases";
import Sales from "./Sales/Sales";

import Aide from "./Other/Aide";
import CGUNewsletter from "./Other/CGUNewsletter";
import TmpPortfolio from "./Portfolio/TmpPortfolio";
import RecentPhotographers from "./Research/Recent/RecentPhotographers";
import Teaser from "./Teaser/Teaser";
import UnSubscribe from "./Teaser/UnSubscribe";

import ContractsAccount from "./Account/components/Contracts";
import Data from "./Account/components/Data";
import Notifications from "./Account/components/Notifications";
import PortfolioAccount from "./Account/components/Portfolio";
import SubscriptionAccount from "./Account/components/Purchases";
import Security from "./Account/components/Security";
import CartTransaction from "./Cart/CartTransaction";
import AboutEdit from "./Portfolio/componentsEdit/AboutEdit";
import PerformanceAdd from "./Portfolio/componentsEdit/Performances/PerformanceAdd";
import PerformanceDetailEdit from "./Portfolio/componentsEdit/Performances/PerformanceDetailEdit";
import PerformancesEdit from "./Portfolio/componentsEdit/PerformancesEdit";
import PhotosEdit from "./Portfolio/componentsEdit/PhotosEdit";
import AboutPortfolio from "./Portfolio/componentsPortfolio/About";
import ContactPortfolio from "./Portfolio/componentsPortfolio/Contact";
import Appointment from "./Portfolio/componentsPortfolio/Contact/Appointment";
import Inquiry from "./Portfolio/componentsPortfolio/Contact/Inquiry";
import Offers from "./Portfolio/componentsPortfolio/Contact/Offers";
import Opinion from "./Portfolio/componentsPortfolio/Opinion";
import Performances from "./Portfolio/componentsPortfolio/Performances";
import PerformanceDetail from "./Portfolio/componentsPortfolio/Performances/PerformanceDetail";
import Photos from "./Portfolio/componentsPortfolio/Photos";
import EndTransaction from "./Subscription/EndTransaction";

import PortfolioOr404 from "./Bundle/404/PortfolioOr404";
import ContractsEdit from "./Contracts/ContractEdit";
import IamPhotographer from "./Home/IamPhotographer/IamPhotographer";
import Tarifs from "./Other/Tarifs";
import DoubleAuth from "./Signin/DoubleAuth";
import RecoverPassword from "./Signin/RecoverPassword";
import SignupConfirm from "./Signup/SignupConfirm";
import EndTransactionStatic from "./Subscription/EndTransactionStatic";
import Providers from './providers';

const router = (token: string | null) => createBrowserRouter([
    {
        path: "/",
        element: <Providers />, // Use GlobalLayout as the root element
        children: [
            {
                path: "/",
                element: token ? <HomeIn /> : <Home />,
            },
            { path: "/iam-photographer", element: <IamPhotographer /> },
            { path: "/teaser", element: <Teaser /> },
            { path: "/signin", element: <Signin /> },
            { path: "/signup", element: <Signup /> },
            { path: "/confirm-signup", element: <SignupConfirm /> },
            { path: "/verify-email", element: <EmailConfirmation /> },
            { path: "/forgotpassword", element: <ForgotPassword /> },
            { path: "/forgot-password", element: <RecoverPassword /> },
            { path: "/phone_confirmation", element: <PhoneConfirmation /> },
            { path: "/2fa", element: <DoubleAuth /> },
            { path: "/favorites", element: <Favorites /> },
            { path: "/favorites/:type", element: <Favorites /> },
            {
                path: "/gallery/:hashid/edit",
                element: token ? <EditGallery /> : <Navigate to="/" />,
            },
            { path: "/gallery/:hashid", element: <Gallery /> },
            {
                path: "/portfolio/edit",
                element: token ? <EditPortfolio /> : <Navigate to="/" />,
                children: [
                    { path: "photos", element: <PhotosEdit /> },
                    { path: "about", element: <AboutEdit /> },
                    { path: "performances", element: <PerformancesEdit /> },
                    { path: "performance/:perf", element: <PerformanceDetailEdit /> },
                    { path: "addPerf", element: <PerformanceAdd /> },
                ],
            },
            {
                path: "/portfolio/:id",
                element: <Portfolio isPathPersonnal={false} />,
                children: [
                    { path: "photos", element: <Photos /> },
                    { path: "about", element: <AboutPortfolio /> },
                    { path: "performances", element: <Performances /> },
                    { path: "performance/:perf", element: <PerformanceDetail /> },
                    { path: "opinion", element: <Opinion /> },
                    { path: "contact", element: <ContactPortfolio /> },
                    { path: "offers", element: <Offers /> },
                    { path: "appointment", element: <Appointment /> },
                    { path: "inquiry", element: <Inquiry /> },
                ],
            },
            { path: "/my-portfolio", element: <TmpPortfolio /> },
            { path: "/recent", element: <Recent /> },
            { path: "/popular", element: <Popular /> },
            { path: "/galeries", element: <Galeries /> },
            {
                path: "/search",
                children: [
                    { path: "", element: <Search /> },
                    { path: ":type/:value", element: <Search /> },
                ],
            },
            {
                path: "/photographers",
                children: [
                    { path: "", element: <Photographers /> },
                    { path: ":type/:value", element: <Photographers /> },
                ],
            },
            { path: "/popular-photographers", element: <PopularPhotographers /> },
            { path: "/recent-photographers", element: <RecentPhotographers /> },
            {
                path: "/account",
                element: token ? <Account /> : <Navigate to="/" />,
                children: [
                    { path: "data", element: <Data /> },
                    { path: "portfolio", element: <PortfolioAccount /> },
                    { path: "preferences", element: <Notifications /> },
                    { path: "security", element: <Security /> },
                    { path: "subscription", element: <SubscriptionAccount /> },
                    { path: "contracts", element: <ContractsAccount /> },
                    { path: "*", element: <Navigate to="/account/data" /> },
                ],
            },
            { path: "/sales", element: token ? <Sales /> : <Navigate to="/" /> },
            { path: "/sales/:type", element: token ? <Sales /> : <Navigate to="/" /> },
            { path: "/purchases", element: token ? <Purchases /> : <Navigate to="/" /> },
            { path: "/statistics", element: token ? <Stats /> : <Navigate to="/" /> },
            { path: "/statistics/:type", element: token ? <Stats /> : <Navigate to="/" /> },
            { path: "/subscription/:sub_type", element: token ? <Subscription /> : <Navigate to="/" /> },
            { path: "/checkout", element: token ? <CartTransaction /> : <Navigate to="/" /> },
            { path: "/upload", element: token ? <Upload /> : <Navigate to="/" /> },
            { path: "/contact/:type/:id", element: token ? <Contact /> : <Navigate to="/" /> },
            { path: "/contracts", element: token ? <Contracts /> : <Navigate to="/" /> },
            { path: "/contracts/:id", element: token ? <ContractsEdit /> : <Navigate to="/" /> },
            { path: "/download/:contract/:id", element: token ? <Download /> : <Navigate to="/" /> },
            { path: "/checkout/:arg", element: token ? <EndTransaction /> : <Navigate to="/" /> },
            { path: "/checkout-success/:arg", element: token ? <EndTransactionStatic /> : <Navigate to="/" /> },
            { path: "/contact", element: <Contacts /> },
            { path: "/about", element: <About /> },
            { path: "/privacy", element: <Privacy /> },
            { path: "/CGU", element: <Cgu /> },
            { path: "/CGU-newsletter", element: <CGUNewsletter /> },
            { path: "/CGV", element: <Cgv /> },
            { path: "/avis", element: <Avis /> },
            { path: "/tarifs", element: <Tarifs /> },
            { path: "/help", element: <Aide /> },
            { path: "/unsubscribe-newsletter/:id", element: <UnSubscribe /> },
            {
                path: "/:portfolio",
                element: <PortfolioOr404 />,
                children: [
                    { path: "photos", element: <Photos /> },
                    { path: "about", element: <AboutPortfolio /> },
                    { path: "performances", element: <Performances /> },
                    { path: "performance/:perf", element: <PerformanceDetail /> },
                    { path: "opinion", element: <Opinion /> },
                    { path: "contact", element: <ContactPortfolio /> },
                    { path: "offers", element: <Offers /> },
                    { path: "appointment", element: <Appointment /> },
                    { path: "inquiry", element: <Inquiry /> },
                ],
            },
            { path: "/*", element: <Page404 /> },
        ],
    },
]);

export default router;
