import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, IconButton, Input, Option, Radio, Select, Spinner, Textarea, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { confirmAlert, ReactConfirmAlertProps } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BannerDefaultEzoom from "../../../../assets/images/elements/default-banner.png";
import SwipeGaleryUnique from "../../../../components/Box/Gallery/SwipeGaleryUnique";
import PictureWithoutInfinite from "../../../../components/Box/Photo/PictureWithoutInfinite";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import { PerformanceType } from "../../../../components/Interfaces/PerformanceType";
import KeywordsAPI from "../../../../services/API/Clients/KeywordsAPI";
import PortfolioAPI from "../../../../services/API/Clients/PortfolioAPI";
import ServiceAPI from "../../../../services/API/Clients/ServiceAPI";
import ServiceTypeAPI from "../../../../services/API/Clients/ServiceTypeAPI";
import { handleError } from "../../../../services/Errors/handleErrors";
import { updateServices } from "../../../../services/redux/actions/formAction";
import { updateBannerOffsetPE, updateBannerPE, updateDescriptionPE, updateGalleriesPE, updateKeywordsPE, updatePerformancesPE, updatePicturesPE, updatePriceCeilPE, updatePriceTypePE, updatePriceValuePE, updatePublishedPE, updateResetPerformancePE, updateTitlePE, updateTypePE } from "../../../../services/redux/actions/performanceEditAction";
import SelectTypePresta from "./components/SelectTypePresta";
import ModalChangeBannerPerf from "./ModalChangeBackgroundPortfolio";
import ModalSelectGallery from "./ModalSelectGallery";
import ModalSelectPictures from "./ModalSelectPicture";

export default function PerformanceDetailEdit() {
    const { t } = useTranslation();
    const { perf } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const portfolio = useSelector((state: any) => state.form);
    const performance = useSelector((state: any) => state.performanceEdit);
    const [isHover, setIsHover] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [openModalBanner, setOpenModalBanner] = React.useState(false);
    const [openModalPictures, setOpenModalPictures] = React.useState(false);
    const [openModalGalleries, setOpenModalGalleries] = React.useState(false);
    const [showFullList, setShowFullList] = React.useState<boolean>(false);
    const [suggestions, setSuggestions] = React.useState<{
        enumValue: string,
        frLabel: string,
        enLabel: string
    }[]>([]);
    const [servicesTypes, setServicesTypes] = React.useState<
        {
            enumValue: string;
            frLabel: string;
            enLabel: string;
        }[]
    >([]);

    const filteringKeywords = (suggestionHardCode?: []) => {
        let data
        if (suggestionHardCode !== undefined) {
            data = suggestionHardCode
        } else 
            data = suggestions
        if (performance.keywords.length > 0) {
            const myData = data.filter((key) => {
                const enumMatches = performance.keywords.some(
                    (tag: { keyword?: { enumValue?: string } }) => tag?.keyword?.enumValue === key?.enumValue
                );
    
                const labelMatches = performance.keywords.some(
                    (tag: { customLabel?: string }) =>
                        tag.customLabel === key.frLabel || tag.customLabel === key.enLabel
                );
    
                return !(enumMatches || labelMatches);
            });
    
            setSuggestions(myData);
        } else {
            setSuggestions(data);
        }
    }

    const addTag = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            const textMajor = event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1);
            const myData = {
                customLabel: textMajor,
                index: performance.keywords.length,
            };
            const newList = [...performance.keywords, myData]
            dispatch(updateKeywordsPE(newList));
            event.currentTarget.value = "";
        }
    };

    const removeTag = (tag: number) => {
        const filtered = performance.keywords.filter((item: any, index: number) => index !== tag);
        dispatch(updateKeywordsPE(filtered));
    };

    const addTagToList = (value: string, label: string) => {
        const myData = {
            keyword: {
                enumValue: value,
                frLabel: label,
                enLabel: label,
            },
            index: performance.keywords.length,
        };
        const newList = [...performance.keywords, myData];
        dispatch(updateKeywordsPE(newList));
    }

    const fetchDataPerf = async () => {
        const resp = await ServiceTypeAPI.services();
        if (resp.status === 200 || resp.status === 201) {
            setServicesTypes(resp.body);
        } else {
            handleError(resp);
        }

        if (!perf) return;
        const response = await ServiceAPI.getOne(perf);
        if (response.status === 200) {
            const myData = response.body;
            const formattedPictures = response.body.pictures.map(
                (item: { picture: { id: string; minPath: string; width: number; height: number } }) => item.picture
            );
            const formattedGallery = response.body.galleries.map(
                (item: {
                    gallery: {
                        id: string;
                        title: string;
                        banner: { id: string; width: number; height: number; minPath: string };
                        bannerOffset: number;
                        date: string;
                    };
                }) => item.gallery
            );
            const newData: PerformanceType = {
                ...myData,
                pictures: formattedPictures,
                galleries: formattedGallery,
                keywords: myData.keywords
            };
            dispatch(updatePerformancesPE(newData));
            fetchSuggestions(newData.type.enumValue);
            filteringKeywords();
            setIsLoading(true);
        } else {
            handleError(response);
        }
    };

    useEffect(() => {
        fetchDataPerf();
    }, [perf]);

    useEffect(() => {
        filteringKeywords();
    }, [performance.keywords]);

    useEffect(() => {
        if (servicesTypes.length > 0 && performance.type.enumValue === "") {
            const defaultServiceType = servicesTypes[0].enumValue;
            dispatch(updateTypePE({ ...performance.type, enumValue: defaultServiceType }));
        }
    }, [servicesTypes]);

    

    const priceDefine = (
        <div className={`flex ${performance.priceType === "range" ? "flex-col" : "flex-row"} items-center w-full gap-2`}>
            <Select
                label=""
                variant="standard"
                color="orange"
                value={performance.priceType}
                onChange={(e) => {
                    e && dispatch(updatePriceTypePE(e));
                }}
                containerProps={{ className: "min-w-[72px]" }}
            >
                <Option value="fixed">{t("fixprice")}</Option>
                <Option value="from">{t("from")}</Option>
                <Option value="range">{t("interval")}</Option>
            </Select>
            <div className="flex flex-row items-center gap-2">
                <Input
                    type="number"
                    color="orange"
                    label={t("chf")}
                    value={performance.priceValue}
                    onChange={(e) => dispatch(updatePriceValuePE(Number(e.target.value)))}
                    containerProps={{ className: "min-w-[72px]" }}
                />
                {performance.priceType === "range" && (
                    <Input
                        type="number"
                        color="orange"
                        label={t("chf")}
                        value={performance.priceCeil}
                        onChange={(e) => dispatch(updatePriceCeilPE(Number(e.target.value)))}
                        containerProps={{ className: "min-w-[72px]" }}
                    />
                )}
            </div>
        </div>
    );

    const publishIt = async (id: string) => {
        const responsePublish = await ServiceAPI.publish(id);
        if (responsePublish.status === 200 || responsePublish.status === 201) {
            toast.success(t("published"));
            dispatch(updatePublishedPE(true)); 
            const getAllServcies = await PortfolioAPI.getServicesDraft(performance.portfolio.id);
            if (getAllServcies.status === 200 || getAllServcies.status === 201) {
                dispatch(updateServices(getAllServcies.body));
            } else handleError(getAllServcies);
        } else {
            handleError(responsePublish);
        }
    };

    const checkingSubmit = () => {
        if (!performance?.banner || !performance?.banner?.id) {
            toast.warning(t("nobanner"));
            return false;
        } else if (!performance.type) {
            toast.warning(t("notype"));
            return false;
        } else if (!performance.title) {
            toast.warning(t("notitle"));
            return false;
        } else if (!performance.portfolio || !performance.portfolio.id) {
            toast.warning(t("noidPortfolio"));
            return false;
        } else if (
            (performance.priceType === "fixed" || performance.priceType === "from" || performance.priceType === "range") &&
            performance.priceValue === 0
        ) {
            toast.warning(t("noprice"));
            return false;
        } else if (performance.priceType === "range" && performance.priceCeil === 0) {
            toast.warning(t("nopriceRange"));
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = async (arg: boolean) => {
        if (!checkingSubmit()) {
            return;
        } else {
            const datas = {
                banner: `/api/public/pictures/${performance?.banner?.id}`,
                title: performance.title,
                portfolio: `/api/public/portfolios/${performance.portfolio.id}`,
                bannerOffset: performance?.bannerOffset ?? 0,
                ...(performance.description !== "" && { description: performance.description }),
                ...(performance.priceType !== "" && { priceType: performance.priceType }),
                ...((performance.priceType === "fixed" || performance.priceType === "from" || performance.priceType === "range") &&
                    performance.priceValue !== 0 && { priceValue: performance.priceValue }),
                ...(performance.priceType === "range" && performance.priceCeil !== 0 && { priceCeil: performance.priceCeil }),
                ...(performance.type.enumValue !== "" && { type: `/api/service-types/${performance.type.enumValue}` }),
                ...(performance.pictures.length > 0 && {
                    pictures: performance.pictures.map((picture: { id: string }, index: number) => {
                        return {
                            picture: `/api/public/pictures/${picture.id}`,
                            index: index,
                        };
                    }),
                }),
                ...(performance.galleries.length > 0 && {
                    galleries: performance.galleries.map((gallery: { id: string }, index: number) => {
                        return {
                            gallery: `/api/public/galleries/${gallery.id}`,
                            index: index,
                        };
                    }),
                }),
                ...(performance.keywords.length > 0 && {
                    keywords: performance.keywords.map((keyword: { customLabel?: string, keyword?: { enumValue?: string, frLabel?: string, enLabel?: string }, index: number}, index: number) => {
                        if (keyword.customLabel) {
                            return {
                                customLabel: keyword.customLabel,
                                index: index,
                            };
                        } else {
                            return {
                                keyword: `/api/keywords/${keyword.keyword?.enumValue}`,
                                index: index,
                            };
                        }
                    }),
                }),
            };
            const response = await ServiceAPI.edit(performance.id, datas);
            if (response.status === 200 || response.status === 201) {
                toast.success(t("successServiceEdit"));
                if (arg) {
                    publishIt(response.body.id);
                }
            } else {
                handleError(response);
            }
        }
    };

    const deleteMyPerf = async () => {
        const response = await ServiceAPI.delete(performance.id);
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            const getAllServcies = await PortfolioAPI.getServicesDraft(performance.portfolio.id);
            if (getAllServcies.status === 200 || getAllServcies.status === 201 || getAllServcies.status === 204) {
                dispatch(updateServices(getAllServcies.body));
                dispatch(updateResetPerformancePE());
                navigate("/portfolio/edit/performances");
            } else {
                dispatch(updateResetPerformancePE());
                handleError(getAllServcies);
            }
        } else {
            handleError(response);
        }
    };

    const handleOpenModalBanner = () => {
        setOpenModalBanner(!openModalBanner);
    };

    const handleOpenModalPicture = () => {
        setOpenModalPictures(!openModalPictures);
    };

    const handleOpenModalGallery = () => {
        setOpenModalGalleries(!openModalGalleries);
    };

    const handleChangeBackground = (data: { id: string; minPath: string }) => {
        dispatch(updateBannerPE({ ...performance.banner, id: data.id, minPath: data.minPath }));
    };

    const handleChangePictures = (pictures: any) => {
        dispatch(updatePicturesPE(pictures));
    };

    const handleChangeGalleries = (galleries: any) => {
        dispatch(updateGalleriesPE(galleries));
    };

    const fetchSuggestions = async (e: string) => {
        const response = await KeywordsAPI.keywordsPerformance(e);
        if (response.status === 200 || response.status === 201) {
            setSuggestions(response.body);
            filteringKeywords(response.body);
        } else {
            handleError(response);
        }
    }
    
    const handleSelectType = async (e: string) => {
        dispatch(updateTypePE({...performance.type, enumValue: e}))
        fetchSuggestions(e);
    }

    const deleteAlert = (deleteRoutine: () => void, t: any): ReactConfirmAlertProps => ({
        title: t("delete"),
        message: t("delete_d_performance"),
        buttons: [
            {
                label: t("delete"),
                className: "ezoom-alert-cancel",
                onClick: deleteRoutine,
            },
            {
                label: t("keep"),
            },
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        overlayClassName: "ezoom-alert-overlay",
    });

    const handleDelete = () => {
        confirmAlert(
            deleteAlert(async () => {
                const response = await ServiceAPI.delete(performance.id);
                if (response.status === 200 || response.status === 201 || response.status === 204) {
                    const getAllServcies = await PortfolioAPI.getServicesDraft(performance.portfolio.id);
                    if (getAllServcies.status === 200 || getAllServcies.status === 201 || getAllServcies.status === 204) {
                        dispatch(updateServices(getAllServcies.body));
                        dispatch(updateResetPerformancePE());
                        navigate("/portfolio/edit/performances");
                    } else {
                        handleError(getAllServcies);
                        dispatch(updateResetPerformancePE());
                    }
                } else {
                    handleError(response);
                }
            }, t)
        );
    };

    if (!isLoading) return <Spinner color="orange" className="m-auto h-8 w-8 mt-12" />;

    return (
        <>
            <ModalChangeBannerPerf open={openModalBanner} setOpen={handleOpenModalBanner} setBackground={handleChangeBackground} />
            <ModalSelectPictures open={openModalPictures} setOpen={handleOpenModalPicture} pictures={performance.pictures} setPictures={handleChangePictures} />
            <ModalSelectGallery
                open={openModalGalleries}
                setOpen={handleOpenModalGallery}
                galleries={performance.galleries}
                setGalleries={handleChangeGalleries}
            />

            <div className="relative mx-auto px-2 md:px-4 py-6 flex flex-col md:flex-row flex-wrap w-full max-w-6xl">
                <div className="w-full flex flex-row items-center justify-between md:px-4">
                    <div 
                        className="flex flex-row items-center cursor-pointer"
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                        onClick={() => {
                            dispatch(updateResetPerformancePE());
                            navigate("/portfolio/edit/performances")
                        }}
                    >
                        <div
                            className={`w-6 text-sm h-6 mr-2 flex items-center justify-center border transition-all ${isHover ? " hover:border-purple-50 hover:text-purple-50" : "border-purple-500 text-purple-500"} rounded-lg`}
                        >
                            <FontAwesomeIcon icon="arrow-left" />
                        </div>
                        <Typography className={`hidden md:flex text-lg font-bold text-center ${isHover ? "text-gray-500" : "text-black"} transition-all`}>{t("backtoperf")}</Typography>
                    </div>
                    <div className="flex flex-row">
                        {portfolio.published && !performance.published && (
                            <>
                                <button onClick={() => handleSubmit(true)} className="hidden md:flex">
                                    <GradientButton text={t("saveandpublish")} />
                                </button>
                                <button onClick={() => handleSubmit(true)} className="md:hidden">
                                    <IconButton className="rounded-full hover:bg-orange-200 transition-all duration-400" color="orange" size="sm">
                                        <FontAwesomeIcon icon="check" />
                                    </IconButton>
                                </button>
                            </>
                        )}
                        <button onClick={() => handleSubmit(false)} className="hidden md:flex mx-4">
                            <GradientButton text={t("save")} />
                        </button>
                        <button onClick={() => handleSubmit(false)} className="md:hidden mx-2">
                            <IconButton className="rounded-full bg-white border text-black hover:bg-gray-50 transition-all duration-400" size="sm">
                                <FontAwesomeIcon icon="save" />
                            </IconButton>
                        </button>
                        <button
                            onClick={() => handleDelete()}
                            className="hidden md:flex border border-indian-500 hover:border-indian-500/50 text-indian-500 hover:text-indian-500/50 bg-white hover:bg-white/75 transition-all w-auto py-1 px-4 rounded-full items-center"
                        >
                            <FontAwesomeIcon icon="trash" className="mr-2" />
                            {t("delete")}
                        </button>
                        <button
                            onClick={() => deleteMyPerf()}
                            className="md:hidden border  relative align-middle select-none font-sans font-medium text-center uppercase disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-8 max-w-[32px] h-8 max-h-[32px] text-xs shadow-md shadow-indian-500/20 hover:shadow-lg hover:shadow-indian-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none rounded-full bg-white border-indian-500 text-indian-500 hover:bg-gray-50 transition-all duration-400"
                        >
                            <FontAwesomeIcon icon="trash" />
                        </button>
                    </div>
                </div>
                <div className="w-full h-72 mt-4">
                    <Card shadow={false} className="relative grid h-72 w-full items-end justify-center overflow-hidden text-center">
                        <CardHeader
                            floated={false}
                            shadow={false}
                            color="transparent"
                            className="absolute inset-0 m-0 h-full w-full rounded-none bg-cover bg-center"
                            style={{
                                backgroundImage: `url("${performance?.banner?.minPath ?? BannerDefaultEzoom}")`,
                                backgroundPositionY: `${100 - (performance?.bannerOffset ?? 0)}%`,
                            }}
                        >
                            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/90 via-black/20" />
                        </CardHeader>
                        <div style={{ height: "220px", width: "50px" }} className="absolute bottom-0 top-2 right-2 flex flex-col items-center justify-center">
                            <input
                                type="range"
                                min={0}
                                max={100}
                                value={performance?.bannerOffset ?? 0}
                                onChange={(event) => dispatch(updateBannerOffsetPE(Number(event.target.value)))}
                                className="-rotate-90 transparent h-[4px] w-[220px] cursor-pointer appearance-none border-transparent bg-neutral-200 dark:bg-neutral-600 rounded-full"
                                id="customRange1"
                            />
                        </div>
                        <button
                            color="white"
                            className="align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] text-xs bg-white text-blue-gray-900 shadow-md shadow-blue-gray-500/10 hover:shadow-lg hover:shadow-blue-gray-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50 cursor-pointer absolute right-3 bottom-3"
                            onClick={() => setOpenModalBanner(!openModalBanner)}
                        >
                            <FontAwesomeIcon icon="pen" />
                        </button>
                    </Card>
                </div>
                <div className="w-full flex flex-col md:flex-row mt-4 md:mx-2 gap-4">
                    <div className="w-full md:w-2/3 flex flex-col gap-4">
                        <div className="w-full flex flex-col gap-4 bg-white p-2 rounded">
                            <Input
                                type="text"
                                color="orange"
                                label={t("title")}
                                value={performance.title}
                                onChange={(e) => dispatch(updateTitlePE(e.target.value))}
                                className="bg-white"
                            />
                            <Textarea
                                color="orange"
                                label={t("captionperf")}
                                value={performance.description ?? ""}
                                onChange={(e) => dispatch(updateDescriptionPE(e.target.value))}
                                className="bg-white"
                            />
                        </div>
                        <div className="bg-white p-2 rounded hidden md:block">
                            <div className="flex flex-col md:flex-row flex-wrap justify-between items-center mb-3">
                                <Typography className="text-base ml-2 font-bold text-left">{t("photos")}</Typography>
                                <button
                                    className="border text-xs inline w-fit hover:text-black hover:bg-gray-50 transition-all py-2 mt-0.5 px-4 rounded-full"
                                    onClick={() => setOpenModalPictures(!openModalPictures)}
                                >
                                    {t("addpicture")}
                                </button>
                            </div>
                            <PictureWithoutInfinite pictures={performance.pictures} heart={true} />
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 flex flex-col">
                        <div className="w-full flex-col rounded bg-white p-2">
                            <Typography className="text-base ml-2 font-bold text-left">{t("price")}</Typography>
                            <Radio
                                label={priceDefine}
                                color="orange"
                                name="priceMobile"
                                value="define"
                                checked={performance.priceType === "fixed" || performance.priceType === "from" || performance.priceType === "range"}
                                onChange={() => dispatch(updatePriceTypePE("fixed"))}
                            />
                            <Radio
                                label={t("ondemand")}
                                color="orange"
                                name="priceMobile"
                                value="ondemand"
                                checked={performance.priceType === "ondemand"}
                                onChange={() => dispatch(updatePriceTypePE("ondemand"))}
                            />
                            <Radio
                                label={t("free")}
                                color="orange"
                                name="priceMobile"
                                value="free"
                                checked={performance.priceType === "free"}
                                onChange={() => dispatch(updatePriceTypePE("free"))}
                            />
                        </div>
                        <div className="w-full flex-col rounded bg-white p-2 mt-4 flex">
                            <Typography className="text-base ml-2 font-bold text-left">{t("keywords")}</Typography>
                            <Typography className="text-sm ml-2 text-justify">{t("choosecategory")}</Typography>

                            <div className="mt-2 w-full">
                                <SelectTypePresta
                                    list={servicesTypes}
                                    onChange={(e: any) => handleSelectType(e)}
                                    selectedValue={performance.type.enumValue}
                                />
                            </div>
                            <div className="mt-4">
                                <Input
                                    className="bg-white"
                                    label={t("tags")}
                                    color="orange"
                                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => addTag(event)}
                                />
                                <div className="flex flex-row flex-wrap gap-2 mt-2">
                                    {performance.keywords.map((tag: { customLabel?: string, keyword: { enumValue?: string, frLabel?: string, enLabel?: string } }, index: number) => (
                                        <div className="bg-orange-200/30 text-orange-700 py-1 pl-3 p-2 rounded-full flex justify-between" key={tag.customLabel ?? tag.keyword?.enumValue}>
                                            <p>{tag.customLabel ?? tag.keyword?.frLabel}</p>
                                            <FontAwesomeIcon icon="circle-xmark" className="ml-2 cursor-pointer mt-1" onClick={() => removeTag(index)} />
                                        </div>
                                    ))}
                                </div>
                                {suggestions.length > 0 && (
                                    <div
                                        className={`flex flex-row relative justify-between overflow-hidden ${
                                            showFullList ? "h-auto pb-7 items-end" : "h-10 items-start"
                                        } transition-all duration-700 ease-in-out`}
                                    >
                                        <div className="flex flex-row flex-wrap gap-2 mt-2">
                                            {suggestions.map((tag) => (
                                                <button key={tag?.enumValue ?? ""} className="hover:opacity-90" onClick={() => addTagToList(tag?.enumValue ?? "", tag?.frLabel ?? "")}>
                                                    <div className="bg-blue-gray-200/30 text-blue-gray-700 py-1 pl-3 p-2 rounded-full flex justify-between" key={tag?.enumValue ?? ""}>
                                                        <p>{tag?.frLabel ?? ""}</p>
                                                    </div>
                                                </button>
                                            ))}
                                        </div>
                                        <button
                                            className="h-10 mt-0.5"
                                            onClick={() => setShowFullList(!showFullList)}
                                        >
                                            <div className="bg-orange-200/30 text-orange-700 py-1 pl-3 p-2 rounded-full flex justify-between">
                                                {showFullList ? <FontAwesomeIcon icon="chevron-up" /> : "..."}
                                            </div>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-full flex-col rounded bg-white p-2 mt-4 flex">
                            <div className="flex flex-col md:flex-row flex-wrap justify-between items-center">
                                <div className="flex flex-row items-center justify-between w-full mb-2 md:mb-3">
                                    <Typography className="text-base ml-2 font-bold text-left">{t("galeries")}</Typography>
                                    <button
                                        className="border text-xs inline w-fit hover:text-black hover:bg-gray-50 transition-all py-2 mt-0.5 px-4 rounded-full"
                                        onClick={() => setOpenModalGalleries(!openModalGalleries)}
                                    >
                                        {t("addgal")}
                                    </button>
                                </div>
                                {performance.galleries.length > 0 ? (
                                    <SwipeGaleryUnique
                                        galeries={performance.galleries.map((gallerie: { id: string, title: string, banner?: { minPath: string }, bannerOffset: number}) => {
                                            return {
                                                id: gallerie.id,
                                                title: gallerie.title,
                                                banner: {
                                                    minPath: gallerie?.banner?.minPath ?? BannerDefaultEzoom,
                                                },
                                                bannerOffset: gallerie.bannerOffset,
                                            };
                                        })}
                                    />
                                ) : (
                                    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-50">
                                        <FontAwesomeIcon icon="images" className="mt-12 text-4xl text-gray-500/50 mb-4" />
                                        <Typography className="text-base text-gray-700 mb-12">{t("noGallerie")}</Typography>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="md:hidden bg-white p-2 rounded mt-4">
                            <div className="flex flex-row flex-wrap justify-between items-center mb-2">
                                <Typography className="text-base ml-2 font-bold text-left">{t("photos")}</Typography>
                                <button
                                    className="border text-xs inline w-fit hover:text-black hover:bg-gray-50 transition-all py-2 mt-0.5 px-4 rounded-full"
                                    onClick={() => setOpenModalPictures(!openModalPictures)}
                                >
                                    {t("addpicture")}
                                </button>
                            </div>
                            <PictureWithoutInfinite pictures={performance.pictures} heart={true} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export const savePerformance = async (performance: PerformanceType, t: any, dispatch: any) => {
    const datas = {
        banner: `/api/public/pictures/${performance?.banner?.id}`,
        title: performance.title,
        portfolio: `/api/public/portfolios/${performance.portfolio.id}`,
        bannerOffset: performance?.bannerOffset ?? 0,
        ...(performance.description !== "" && { description: performance.description }),
        ...(performance.priceType !== "" && { priceType: performance.priceType }),
        ...((performance.priceType === "fixed" || performance.priceType === "from" || performance.priceType === "range") &&
            performance.priceValue !== 0 && { priceValue: performance.priceValue }),
        ...(performance.priceType === "range" && performance.priceCeil !== 0 && { priceCeil: performance.priceCeil }),
        ...(performance.type.enumValue !== "" && { type: `/api/service-types/${performance.type.enumValue}` }),
        ...(performance.pictures.length > 0 && {
            pictures: performance.pictures.map((picture: { id: string }, index: number) => {
                return {
                    picture: `/api/public/pictures/${picture.id}`,
                    index: index,
                };
            }),
        }),
        ...(performance.galleries.length > 0 && {
            galleries: performance.galleries.map((gallery: { id: string }, index: number) => {
                return {
                    gallery: `/api/public/galleries/${gallery.id}`,
                    index: index,
                };
            }),
        }),
        ...(performance.keywords.length > 0 && {
            keywords: performance.keywords.map((keyword: {
                customLabel?: string;
            }, index: number) => {
                return {
                    customLabel: keyword,
                    index: index,
                };
            }),
        }),
    };
    const response = await ServiceAPI.edit(performance.id, datas);
    if (response.status === 200 || response.status === 201) {
        toast.success(t("successServiceEdit"));
        dispatch(updateResetPerformancePE());
    } else {
        handleError(response);
    }
}