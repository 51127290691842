import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import GalleryAPI from "../../../services/API/Clients/GalleryAPI";
import Gallery from "../../Box/Gallery/Gallery";
import { Galleries } from "../../Interfaces/PortfolioType";
import { FilterConfiguration } from "../filters/FilterConfiguration";
import DrawerForFilter from "../mobile/DrawerForFilter";
import {
  CompatibleFilterList,
  FilterList,
  FilterRenderConfiguration,
  FilterSchema,
  FilterType,
  PaginatedResponse,
  SearchInterface,
} from "../types/global";
import { handleError } from "../../../services/Errors/handleErrors";
import { useTranslation } from "react-i18next";
import { FilterProvider } from "../provider/FilterProvider";

interface EnumValue {
  enumValue: string;
  frLabel: string;
  enLabel: string;
}

interface GalleryCompatibleFilters extends CompatibleFilterList {
  category: EnumValue[];
  typestyle: EnumValue[];
  date: {
    takeDate: string;
  }[];
  region: {
    code: string;
  }[];
}

interface GalleryFilterList extends FilterList {
  query?: string;
  category?: string;
  typestyle?: string;
  portfolio?: string;
  date?: string;
  country?: string;
  region?: string;
  from_followed?: boolean;
  favorites?: boolean;
  keyword?: string;
}

export const GalleryFilterSchema: FilterSchema<GalleryFilterList> = {
  query: "string",
  category: "string",
  typestyle: "string",
  portfolio: "string",
  keyword: "string",
  date: "string",
  country: "string",
  region: "string",
  from_followed: "boolean",
  favorites: "boolean",
  galleries: "boolean",
};

const GalleryFiltersRender: FilterRenderConfiguration<GalleryCompatibleFilters> = {
  galleries: {
    type: FilterType.BOOLEAN,
    mobile: FilterType.BOOLEAN,
    getKey: () => "galleries",
    getValue: (value) => "true",
  },
  category: {
    type: FilterType.DROPDOWN,
    mobile: FilterType.DROPDOWN,
    getKey: (o: EnumValue) => o.enumValue,
    getValue: (o: EnumValue) => o.frLabel,
  },
  typestyle: {
    type: FilterType.DROPDOWN,
    mobile: FilterType.DROPDOWN,
    getKey: (o: EnumValue) => o.enumValue,
    getValue: (o: EnumValue) => o.frLabel,
  },
  date: {
    type: FilterType.DATEPICKER,
    mobile: FilterType.DATEPICKER,
    getKey: (o: { takeDate: string }) => o.takeDate,
    getValue: (o: { takeDate: string }) => o.takeDate,
  },
  region: {
    type: FilterType.MAPPICKER,
    mobile: FilterType.DROPDOWN,
    getKey: (o: { code: string }) => o.code,
    getValue: (o: { code: string }) => o.code,
  },
};

const fetchGalleries = async (search: SearchInterface<GalleryFilterList>) => {
  const response = await GalleryAPI.searchGalleriePagination(
    search.filters.getApiQueryParams(),
    search.page
  );
  if (response.status === 200) {
    return response.body as PaginatedResponse<Galleries>;
  } else {
    handleError(response);
  }
};

const fetchCompatibleFilters = async (filters: FilterConfiguration<GalleryFilterList>) => {
  const response = await GalleryAPI.compatibleFilters(filters.getApiQueryParams());
  if (response.status === 200) {
    return response.body as GalleryCompatibleFilters;
  } else {
    handleError(response);
  }
};


interface GalleryResultProps {
  filters?: boolean;
  overrides?: GalleryFilterList;
  children?: React.ReactNode; // Accept children to be rendered within the FilterProvider
  defaultSort?: string;
}

const PictureNoResults = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
      <FontAwesomeIcon icon="image" className="text-3xl text-gray-500/50 mt-4" />
      <Typography className="text-base text-gray-500 font-sans mt-4 text-center mb-4">{t("noPictures")}</Typography>
    </div>
  );
};

export const GalleryResult = (props: GalleryResultProps) => {
  return (
    <FilterProvider
      hideFilters={[]}
      filtersSchema={GalleryFilterSchema}
      filters={props.filters ?? true}
      filtersRender={GalleryFiltersRender}
      fetchResults={fetchGalleries}
      fetchFilters={fetchCompatibleFilters}
      fetchGalleries={undefined}
      filterOverrides={props.overrides}
      NoResults={<PictureNoResults />}
      sortOptions={[
        { key: "viewcount", value: "sort.viewcount" },
        { key: "favoritecount", value: "sort.favoritecount" },
        { key: "publishdate", value: "sort.publishdate" },
      ]}
      renderEntity={(item, index, context, loadMore) => <Gallery key={item.id} data={item} />}
      defaultSort={props.defaultSort}
    >
      {/* Render any children passed to GalleryResult */}
      {props.children}
    </FilterProvider>
  );
};

// For the drawer filters:
export const GalleryDrawerForFilters = () => (
  <DrawerForFilter<GalleryFilterList, GalleryCompatibleFilters>
    renderConfiguration={GalleryFiltersRender}
  />
);
