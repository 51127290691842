import moment from "moment";
import { Galleries } from "../../Interfaces/PortfolioType";
import DefaultBannerEzoom from "../../../assets/images/elements/default-banner.png";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAlert from "../../Modals/ModalAlert";
import React from "react";
import { toast } from "react-toastify";
import GalleryAPI from "../../../services/API/Clients/GalleryAPI";
import { handleError } from "../../../services/Errors/handleErrors";
import { Storage } from "../../../services/storage";
import ModalSignInUp from "../../Modals/ModalSignInUp";
import FavoriteAPI from "../../../services/API/Clients/FavoriteAPI";

const myId = Storage.getId();

export default function Gallery({ data }: Readonly<{ data: Galleries }>) {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = React.useState(false);
    const [openInvitation, setOpenInvitation] = React.useState(false);
    const currentUrl = window.location.href.split("/")[3];
    const { t } = useTranslation();

    const handleClose = () => {
        setOpenModal(false);
    }

    const handleDeleteGallery = async () => {
        setOpenModal(false);
        const response = await GalleryAPI.deleteGallerie(data.id);
        if (response.status === 204) {
            toast.success(t("galleryDeleted"));
            navigate(0);
        } else handleError(response);
    }

    const handleChangeFav = async () => {
        if (myId) {  
            const datas = {
                gallery: `/api/public/galleries/${data.id}`,
                owner: `/api/users/${myId}`
            }
            if (!data.isFavorited) {
                const response = await FavoriteAPI.favGallerie(datas);
                if (response.status === 200 || response.status === 201)
                    toast.success(t("addToFavGallerie"))
                else
                    handleError(response);
            } else {
                const response = await FavoriteAPI.unFavGallerie(datas);
                if (response.status === 200 || response.status === 201 || response.status === 204)
                    toast.success(t("removeFromFavGallerie"))
                else
                    handleError(response);
            }
        }
    }
    
    return (
        <>
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <ModalAlert open={openModal} setOpen={handleClose} handleSave={handleDeleteGallery} />
            <div className={`mx-auto relative w-full ${(currentUrl !== undefined && currentUrl !== "") ? "md:w-96" : "md:w-full"}`}>
                {data.isOwned && (
                    <div className="absolute top-[152px] right-2 flex gap-2 z-10">
                        <Link to={`/gallery/${data.id}/edit`}>
                            <IconButton 
                                color="white" 
                                size="sm" 
                                variant="outlined"
                                className="flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                            >
                                <FontAwesomeIcon icon="pen" />
                            </IconButton>
                        </Link>
                        <IconButton 
                            color="white" 
                            size="sm" 
                            variant="outlined"
                            className="flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                            onClick={() => setOpenModal(true)}
                        >
                            <FontAwesomeIcon icon="trash" />
                        </IconButton>
                    </div>
                )} 
                {!data.isOwned && (
                    <div className="absolute top-[152px] right-2 flex gap-2 z-10">
                        <IconButton 
                            color={data.isFavorited ? "red" : "white"} 
                            size="sm" 
                            variant="outlined"
                            onClick={() => !Storage.getToken() ? setOpenInvitation(true) : handleChangeFav()}
                            className={`ring-1 ${data.isFavorited ? "ring-red-500/50 rounded-full hover:bg-red-400 text-white" : "ring-gray-400/50 rounded-full bg-white text-black hover:bg-gray-50"} rounded-full transition-all`} 
                        >
                            <FontAwesomeIcon icon="heart" />
                        </IconButton>
                    </div>
                )} 
                <Link className="w-full mb-5 hover:opacity-75 transition-all" to={`/gallery/${data.id}`}>
                    <div className={`w-full ${(currentUrl !== undefined && currentUrl !== "") ? "md:w-96" : "md:w-full"} mx-auto rounded overflow-hidden shadow-lg h-full relative`} key={data.title}>
                        {data.private || data.published && (
                            <div className="absolute top-2 left-2 z-10 flex gap-2">
                                {data.private && (
                                    <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                                        {t("privateGallery")}
                                    </div>
                                )}
                                {!data.published && (
                                    <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                                        {t("unpublishedGallery")}
                                    </div>
                                )}
                            </div>
                        )}
                        <div
                            className={`w-full ${(currentUrl !== undefined && currentUrl !== "") ? "md:w-96" : "md:w-full"} h-48 flex relative bg-cover`}
                            style={{
                                backgroundPositionY: `${100 - (data.bannerOffset ?? 0)}%`,
                                backgroundImage: `url(${(data.banner && data.banner.minPath) ?? DefaultBannerEzoom})`,
                            }}
                        >
                        </div>
                        <div className="px-6 pt-4 pb-4 flex flex-col items-center justify-center h-20">
                            <div className="font-bold text-base text-center">{data.title}</div>    
                            {data.date && (
                                <p className="text-gray-700 text-sm text-center">{moment(data.date).format("DD.MM.YYYY")}</p>
                            )}
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
}
