import { Collapse } from "@material-tailwind/react";
import TitleDescribe from "./components/TitleDescribe";
import CheckBoxDescribe from "./components/CheckBoxDescribe";
import IconOpen from "./components/IconOpen";
import { useRef } from "react";
import { t } from "i18next";

export default function Identification({
    toggleOpenIdentifiable,
    openIdentifiable,
    identification,
    setIdentification,
    writtenAccords,
    existants = 0,
    setWrittenAccords,
    withOpeners = true,
    nbrAlreadyImported,
    canUpdateIdentifiables = false
}: Readonly<{
    toggleOpenIdentifiable: () => void;
    openIdentifiable: boolean;
    identification: {
        people: boolean;
        properties: boolean;
        writtenContract: boolean;
    };
    setIdentification: Function;
    writtenAccords: File[];
    existants?: number;
    setWrittenAccords: Function;
    withOpeners?: boolean;
    nbrAlreadyImported?: number;
    canUpdateIdentifiables?: boolean;
}>) {
    const inputFile = useRef<HTMLInputElement>(null);

    const sendNewAccords = () => inputFile.current?.click();

    const newUpload = () => {
        if (inputFile.current?.files) {
            const files = Array.from(inputFile.current.files);
            setWrittenAccords(writtenAccords.length ? writtenAccords.concat(files) : files);
        }
    };

    return (
        <div className="pb-4 border-b border-gray-300">
            <button onClick={toggleOpenIdentifiable} className="flex justify-between items-center w-full mt-4 text-left">
                <TitleDescribe title="people_ident" describe="people_prop_ident" />
                {withOpeners && <IconOpen open={openIdentifiable} />}
            </button>
            <Collapse open={openIdentifiable} className="flex flex-col gap-4 pt-2">
                <div className="flex flex-col pl-1">
                    {['people', 'properties', 'writtenContract'].map((key, index) => (
                        <CheckBoxDescribe
                            key={key}
                            label={key === 'people' ? "people_ident" : key === 'properties' ? "prop_ident" : "written_auth"}
                            name={key}
                            describe={key === 'people' ? "people_ident_d" : key === 'properties' ? "prop_ident_d" : "written_auth_d"}
                            checked={identification[key as keyof typeof identification]}
                            index={index}
                            setIdentification={() => setIdentification(key, !identification[key as keyof typeof identification])}
                            disabled={canUpdateIdentifiables}
                        />
                    ))}
                    <button
                        className={`${identification.writtenContract ? nbrAlreadyImported && nbrAlreadyImported > 0 ? "h-20" : "h-16" : "h-0 overflow-hidden"} transition-all cursor-pointer`}
                        onClick={sendNewAccords}
                    >
                        <input ref={inputFile} type="file" className="hidden" multiple onChange={newUpload} />
                        <div className="flex flex-col items-center text-sm py-2 px-3 border rounded mt-2">
                            <p className="text-gray-900">{t("upload_files")}</p>
                            <p className="text-xs text-gray-500">{t("selected_files", { count: writtenAccords.length })}</p>
                            {nbrAlreadyImported && nbrAlreadyImported > 0 && (
                                <p className="text-xs text-gray-500">{t("existing_files", { count: nbrAlreadyImported })}</p>
                            )}
                        </div>
                    </button>
                </div>
            </Collapse>
        </div>
    );
}
