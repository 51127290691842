import RegionalTop from "../../../../assets/images/home/waves/regional_top.svg";
import RegionalBottom from "../../../../assets/images/home/waves/regional_bottom.svg";
import Ipad from "../../../../assets/images/home/ezoom_ipad-02.png";
import Iphone from "../../../../assets/images/home/ezoom_iphone.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export default function Community() {
    const { t } = useTranslation();
    return (
        <>
            <div className="m-0 pb-10 relative bg-cover bg-no-repeat bg-white overflow-hidden">
                <RegionalTop />
                <div style={{ backgroundImage: "linear-gradient(90deg, #ffd3a6 0%, #fff9f3 85%)" }} className="pb-12 pt-20 px-0 -my-1.5 mx-0">
                    <p className="mt-0 px-4 md:px-0 text-left md:text-center text-4xl font-sans max-w-7xl mx-auto">{t("home_section3_title")}</p>
                    <p className="px-4 md:px-0 text-left md:text-center mt-16 mx-auto font-sans">{t("home_section3_subtitle_p1")}</p>
                    <p className="px-4 md:px-0 text-left md:text-center mx-auto font-sans">{t("home_section3_subtitle_p2")}</p>
                </div>
                <RegionalBottom />
            </div>
            <div style={{ paddingBottom: "10px" }} className="bg-white">
                <div className="flex flex-wrap px-0 max-w-7xl mx-auto">
                    <div className="flex flex-col w-full md:w-1/3 items-start md:items-center px-4 mt-4">
                        <FontAwesomeIcon icon="images" className="text-6xl text-orange-500" />
                        <h4 className="text-left md:text-center text-3xl font-semibold my-8">{t("feature_1_title")}</h4>
                        <p className="text-lg text-left">{t("feature_1_description")}</p>
                    </div>

                    <div className="flex flex-col w-full md:w-1/3 items-start md:items-center px-4 mt-4">
                        <FontAwesomeIcon icon="share" className="text-6xl text-orange-500" />
                        <h4 className="text-left md:text-center text-3xl font-semibold my-8">{t("feature_2_title")}</h4>
                        <p className="text-lg text-left">{t("feature_2_description")}</p>
                    </div>

                    <div className="flex flex-col w-full md:w-1/3 items-start md:items-center px-4 mt-4">
                        <FontAwesomeIcon icon="lock" className="text-6xl text-orange-500" />
                        <h4 className="text-left md:text-center text-3xl font-semibold my-8">{t("feature_4_title")}</h4> 
                        <p className="text-lg text-left">{t("feature_4_description")}</p>
                    </div>

                    <div className="flex flex-col w-full md:w-1/3 items-start md:items-center px-4 mt-4 md:mt-16">
                        <FontAwesomeIcon icon="chart-simple" className="text-6xl text-orange-500" />
                        <h4 className="text-left md:text-center text-3xl font-semibold my-8">{t("feature_5_title")}</h4>
                        <p className="text-lg text-left">
                            {t("feature_5_description")}
                            <b className="premium_pro_cond block mt-2">{t("premium_pro_members")}</b>
                        </p>
                    </div>

                    <div className="flex flex-col w-full md:w-1/3 px-4 mt-4">
                    </div>
                    
                    <div className="hidden md:flex w-full md:w-1/3 relative right-0 top-16">
                        <img alt="ipad" src={Ipad} className="absolute top-0 -right-36" />
                        <img alt="iphone" src={Iphone} className="absolute top-0 right-24 w-2/3" />
                    </div>
                </div>
            </div>
        </>
    );
}
