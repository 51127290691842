import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { Portfolio, PortfolioState, Setup } from "../../components/Interfaces/PortfolioType";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobileBis from "../../components/NavBar/HeaderMobile";
import PortfolioAPI from "../../services/API/Clients/PortfolioAPI";
import { updatePortfolio } from "../../services/redux/actions/formAction";
import { RootState } from "../../services/redux/store";
import HeaderDesktop from "./componentsPortfolio/TopParts/Desktop";
import HeaderMobile from "./componentsPortfolio/TopParts/Mobile";
import { handleError } from "../../services/Errors/handleErrors";
import { PictureResult } from "../../components/SearchResult/impl/PictureResult";

export default function PortfolioView({ isPathPersonnal }: Readonly<{ isPathPersonnal: boolean }>) {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("portfolio")}`;
    const currentPage = window.location.pathname.split("/")[3];
    const currentPagePersonnal = window.location.pathname.split("/")[2];
    const [searchBar, setSearchBar] = React.useState(true);
    const portfolio = useSelector((state: RootState) => state.form);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleLinkClick = (url: string) => {
        navigate(url);
    };

    useEffect(() => {
        // if url doesn't finish by /photos or /about, then redirect to /photos
        if (!window.location.pathname.endsWith("/photos")
             && !window.location.pathname.endsWith("/about")
            && !window.location.pathname.endsWith("/performances")
            && !window.location.pathname.endsWith("/addPerf")
            && !window.location.pathname.endsWith(`/performance`)
            && !window.location.pathname.endsWith("/opinion")
            && !window.location.pathname.endsWith("/contact")
            && !window.location.pathname.endsWith("/offers")
            && !window.location.pathname.endsWith("/appointment")
            && !window.location.pathname.endsWith("/inquiry")
            && !/\/performance\/[a-zA-Z0-9-]+$/.test(window.location.pathname)
        ) {
            navigate(window.location.pathname + "/photos");
        }
    }, []);

    useEffect(() => {
        const initPortfolio = async () => {
            let respInitPortfolio = null;

            if (isPathPersonnal) {
                respInitPortfolio = await PortfolioAPI.path(window.location.pathname.split("/")[1])
            } else {
                respInitPortfolio = await PortfolioAPI.portfolio(window.location.pathname.split("/")[2]);
            }
            if (respInitPortfolio.status === 200) {
                await PortfolioAPI.addView({ portfolio: `/api/public/portfolios/${respInitPortfolio.body.id}` })
                try {
                    const [respPictures, respGalleries, respOpinions, respRepartition, respFeatures, respServices] = await Promise.all([
                        PortfolioAPI.getPhotos(respInitPortfolio.body.id),
                        PortfolioAPI.getGalleries(respInitPortfolio.body.id),
                        PortfolioAPI.getOpinions(respInitPortfolio.body.id),
                        PortfolioAPI.getRepartition(respInitPortfolio.body.id),
                        PortfolioAPI.getFeatures(respInitPortfolio.body.id),
                        PortfolioAPI.getServicesWithId(respInitPortfolio.body.id, respInitPortfolio.body.owner.id ?? "blop")
                    ]);

                    const data: Portfolio = respInitPortfolio.body;
                    const state: PortfolioState = {
                        id: data.id,
                        loading: false,
                        name: data.name,
                        path: data.path ?? "",
                        createdAt: data.createdAt,
                        description: data.description,
                        photoStyles: data.photoStyles,
                        regions: data.regions,
                        locales: data.locales,
                        avgNote: data.avgNote ?? -1,
                        flash: data.setup.filter((item: Setup) => item.type === "flash"),
                        body: data.setup.filter((item: Setup) => item.type === "body"),
                        lens: data.setup.filter((item: Setup) => item.type === "lens"),
                        socialNetworks: data.socialNetworks,
                        owner: data.owner,
                        services: respServices.body,
                        picture: data.picture,
                        published: data.published,
                        isSubscribed: data.isSubscribed,
                        publicLink: data.publicLink,
                        pictureForModif: null,
                        banner: data?.banner?.publicPath,
                        backgroundForModif: { id: "", minPath: "" },
                        bannerOffset: data.bannerOffset,
                        subscriberCount: data.subscriberCount,
                        plan: data.plan,
                        canAddTestimonial: data.canAddTestimonial,
                        canContact: data.canContact,
                        pictures: respPictures.body,
                        galleries: respGalleries.body,
                        opinions: respOpinions.body,
                        repartition: respRepartition.body,
                        features: respFeatures.body,
                        complete: { completionPercentage: 100 },
                        hideLocality: data.hideLocality,
                        locality: data.locality,
                        pictureCount: data.pictureCount,
                        verified: data.verified,
                        public: data.public
                    };

                    dispatch(updatePortfolio(state));
                } catch (error) {
                    console.error(error)
                }
            } else {
                handleError(respInitPortfolio);
                navigate("/404");
            }
        };
        resetPortfolio();
        initPortfolio();
    }, [isPathPersonnal ? currentPage : currentPagePersonnal]);

    const resetPortfolio = () => {
        dispatch(updatePortfolio({
            id: "",
            loading: true,
            name: "",
            path: "",
            createdAt: "",
            description: "",
            photoStyles: [],
            regions: [],
            locales: [],
            avgNote: -1,
            flash: [],
            body: [],
            lens: [],
            socialNetworks: [],
            owner: {
                id: "",
                fullname: "",
                verified: false,
                plan: ""
            },
            hideLocality: false,
            locality: "",
            pictureCount: 0,
            verified: false,
            services: {
                pagination: { current: 0, last: 0, totalItems: 0, parPage: 0 },
                data: []
            },
            picture: "",
            published: false,
            isSubscribed: false,
            pictureForModif: null,
            banner: "",
            backgroundForModif: { id: "", minPath: "" },
            bannerOffset: 0,
            subscriberCount: 0,
            plan: "",
            canAddTestimonial: "",
            canContact: "",
            pictures: { pagination: { current: 0, last: 0, totalItems: 0, parPage: 0 }, data: [] },
            galleries: { pagination: { current: 0, last: 0, totalItems: 0, parPage: 0 }, data: [] },
            opinions: { pagination: { current: 0, last: 0, totalItems: 0, parPage: 0 }, data: [] },
            repartition: {
                users: 0,
                photographers: 0
            },
            publicLink: "",
            features: {
                pages: {
                    photos: false,
                    about: false,
                    services: false,
                    testimonials: false,
                    contact: false
                },
                contact: {
                    offer: false,
                    appointment: false,
                    buy: false,
                    contact: false
                },
                actions: {
                    contact: false,
                    testimonial: false
                }
            },
            complete: { completionPercentage: 0 },
            public: false
        }));

    }

    return (
        <div className="min-h-full relative">
            <Loader loading={portfolio.loading} />
            {portfolio.name && <HeaderForAnimation portfolio={{ name: portfolio.name, path: portfolio.path || `portfolio/${portfolio.id}` }} />}
            <HeaderMobileBis searchBar={searchBar} openSearchBar={setSearchBar} />
            <PictureResult
                overrides={{
                    pagetype: "portfolio",
                    portfolio: portfolio.id,
                }}
            >
            <div className="flex flex-col h-full -mt-3 pb-[340px] md:pb-36">
                <HeaderMobile searchBar={searchBar} actualUrl={isPathPersonnal ? currentPagePersonnal : currentPage} isPersonnal={isPathPersonnal} handleLinkClick={handleLinkClick} />
                <HeaderDesktop actualUrl={isPathPersonnal ? currentPagePersonnal : currentPage} isPersonnal={isPathPersonnal} handleLinkClick={handleLinkClick} />
                <div className="flex-grow w-full bg-gray-50 rounded-b-lg md:rounded-b-none md:rounded-r-lg pb-20">
                    <div className="w-full max-w-7xl mx-auto">{!portfolio.loading && <Outlet />}</div>
                </div>
            </div>
            </PictureResult>
            <Footer />
        </div>
    );
}
