import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

import { faUserPen, faImages, faGears, faShieldHalved, faCopyright, faUserPlus } from "@fortawesome/free-solid-svg-icons";

import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { NavigationCustomStyles } from "../../components/CustomStyles/NavigationCustomStyles";
import Loader from "../../components/Loader";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";

const Menu = [
    {
        name: "data",
        icon: faUserPen,
        text: "personnaldata",
    },
    {
        name: "portfolio",
        icon: faImages,
        text: "myportfolio",
    },
    {
        name: "preferences",
        icon: faGears,
        text: "notifsandpref",
    },
    {
        name: "security",
        icon: faShieldHalved,
        text: "securityandconnection",
    },
    {
        name: "subscription",
        icon: faUserPlus,
        text: "subscription",
    },
    {
        name: "contracts",
        icon: faCopyright,
        text: "contracts",
    },
];

export default function Account() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("settings")}`;
    const { page } = useParams();
    const [actualPage, setActualPage] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [openModal, setOpenModal] = React.useState(false);
    const navigate = useNavigate();
    const [searchBar, setSearchBar] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);

    const MenuList = [
        {
            value: "data",
            label: t("personnaldata"),
        },
        {
            value: "portfolio",
            label: t("myportfolio"),
        },
        {
            value: "preferences",
            label: t("notifsandpref"),
        },
        {
            value: "security",
            label: t("securityandconnection"),
        },
        {
            value: "subscription",
            label: t("subscription"),
        },
        {
            value: "contracts",
            label: t("contracts"),
        },
    ];

    const location = useLocation();

    useEffect(() => {
        setLoading(true)
        if (location.pathname.split("/")[2]) setActualPage(location.pathname.split("/")[2]);
        else setActualPage("data");
        setLoading(false)
    }, [location]);

    const changeOnglet = (arg: string) => {
        setLoading(true)
        navigate(`/account/${arg}`);
        setLoading(false)
    };

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    })

    return (
        <div className="min-h-screen relative">
            <Loader loading={loading} />
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
            <div className="flex flex-col h-full pb-[390px] md:pb-40 max-w-7xl mx-auto">
                <h1 className="mt-8 ml-8 mb-2 text-4xl">{t("settings")}</h1>
                <div className="flex flex-col md:flex-row px-0 md:px-8 w-full mt-2 pb-12">
                    <motion.div
                        className={`border-b md:border-b-0 border-gray-200 w-full px-4 pb-2 md:pb-0 md:px-0 md:w-1/5 sticky md:pt-0 bg-white z-30`}
                        variants={{
                            visible: { top: "48px", paddingTop: "24px" },
                            hidden: { top: "0px", paddingTop: "6px" },
                            barsInactive: { top: "48px", paddingTop: "22px" },
                            barsActive: { top: "102px", paddingTop: "18px" },
                        }}
                        animate={hidden ? searchBar ? "barsInactive" : "hidden" : searchBar ? "barsActive" : "visible"}
                        transition={{
                            duration: 0.35,
                            ease: "easeInOut"
                        }}
                    >
                        <div className="block md:hidden">
                            <Select
                                placeholder="Paramètres"
                                name="Paramètres"
                                className="w-full relative top-0 bg-white"
                                options={MenuList}
                                styles={NavigationCustomStyles}
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                                value={MenuList.find((item) => item.value === actualPage)}
                                onChange={(e) => e !== null && changeOnglet(e.value)}
                                isSearchable={false}
                            />
                        </div>
                        <ul className="hidden md:flex flex-row md:flex-col flex-nowrap -mb-px text-xs font-medium text-center text-gray-500 justify-around w-full">
                            {Menu.map((item) => (
                                <li className="md:mr-2" key={item.name}>
                                    <button
                                        onClick={() => {
                                            changeOnglet(item.name);
                                        }}
                                        className={`${actualPage === item.name
                                                ? "border-orange-400 text-orange-400"
                                                : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                            } border-b-2 md:border-l-2 md:border-b-0 flex-grow md:w-full inline-flex items-center flex-row gap-2 justify-center md:justify-start p-4 rounded-t-lg md:rounded-t-none md:rounded-r-lg group`}
                                    >
                                        <FontAwesomeIcon icon={item.icon} />
                                        <span className="hidden md:flex text-left">{t(item.text)}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </motion.div>
                    <div className="flex-grow w-full md:w-4/5 bg-gray-50 rounded-lg max-w-5xl px-4 mb-4">
                        <Outlet />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
