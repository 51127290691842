import { useMotionValueEvent, useScroll } from "framer-motion";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer/Footer";
import { Portfolio, PortfolioState, Setup } from "../../components/Interfaces/PortfolioType";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import { PictureResult } from "../../components/SearchResult/impl/PictureResult";
import PortfolioAPI from "../../services/API/Clients/PortfolioAPI";
import ServiceAPI from "../../services/API/Clients/ServiceAPI";
import UserAPI from "../../services/API/Clients/UserAPI";
import { handleError } from "../../services/Errors/handleErrors";
import { updatePortfolio, updateServices } from "../../services/redux/actions/formAction";
import { updateResetPerformance } from "../../services/redux/actions/performanceActions";
import { RootState } from "../../services/redux/store";
import { ConfirmLeaveModal, isDeepEqual, useNavigationBlocker } from "../../services/SaveUtils";
import DesktopHeader from "./componentsEdit/TopParts/DesktopHeader";
import MobileHeader from "./componentsEdit/TopParts/MobileHeader";

export default function PortfolioEdit() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("editportfolio")}`;
    const [initialPortfolio, setInitialPortfolio] = React.useState<PortfolioState | null>(null);
    const portfolio = useSelector((state: RootState) => state.form);
    const performance = useSelector((state: RootState) => state.performance);
    const performanceEdit = useSelector((state: RootState) => state.performanceEdit);
    const general = useSelector((state: RootState) => state.general);
    const dispatch = useDispatch();
    const [bgPosition, setBgPosition] = React.useState(0);
    const [hidden, setHidden] = React.useState(false);
    const [href, setHref] = React.useState<string | null>(null);
    const [searchBar, setSearchBar] = React.useState(false);
    const currentPage = window.location.pathname.split("/")[3];
    const [reloadPictures, setReloadPictures] = React.useState<Date>(new Date());
    const [isSaved, setIsSaved] = React.useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const initPortfolio = async () => {
            const response = await UserAPI.me();
            if (response.status !== 200 && response.status !== 201) {
                handleError(response);
                return;
            }

            const respPortfolio = await PortfolioAPI.portfolio(response.body.portfolio.id);
            if (respPortfolio.status !== 200) {
                handleError(respPortfolio);
                return;
            }

            const portfolioId = response.body.portfolio.id;
            try {
                const [respPictures, respGalleries, respOpinions, respServices, respRepartition, respFeatures, complete] = await Promise.all([
                    PortfolioAPI.getPhotos(portfolioId),
                    PortfolioAPI.getGalleries(portfolioId),
                    PortfolioAPI.getOpinions(portfolioId),
                    PortfolioAPI.getServicesDraft(respPortfolio.body.id),
                    PortfolioAPI.getRepartition(respPortfolio.body.id),
                    PortfolioAPI.getFeatures(respPortfolio.body.id),
                    PortfolioAPI.complete(portfolioId)
                ]);

                const data: Portfolio = respPortfolio.body;
                const state: PortfolioState = {
                    id: data.id,
                    loading: false,
                    name: data.name,
                    path: data.path ?? "",
                    createdAt: data.createdAt,
                    description: data.description,
                    photoStyles: data.photoStyles,
                    regions: data.regions,
                    locales: data.locales,
                    flash: data.setup.filter((item: Setup) => item.type === "flash"),
                    body: data.setup.filter((item: Setup) => item.type === "body"),
                    lens: data.setup.filter((item: Setup) => item.type === "lens"),
                    socialNetworks: data.socialNetworks,
                    owner: data.owner,
                    picture: data.picture,
                    published: data.published,
                    pictureForModif: null,
                    banner: data?.banner?.publicPath,
                    backgroundForModif: { id: "", minPath: "" },
                    bannerOffset: data.bannerOffset,
                    subscriberCount: data.subscriberCount,
                    plan: data.plan,
                    canAddTestimonial: data.canAddTestimonial,
                    canContact: data.canContact,
                    pictures: respPictures.body,
                    galleries: respGalleries.body,
                    opinions: respOpinions.body,
                    services: respServices.body,
                    repartition: respRepartition.body,
                    isSubscribed: false,
                    avgNote: data.avgNote ?? -1,
                    features: respFeatures.body,
                    complete: complete.body,
                    publicLink: data.publicLink,
                    hideLocality: data.hideLocality,
                    locality: data.locality,
                    pictureCount: data.pictureCount,
                    verified: data.verified,
                    public: data.public,
                };
                setInitialPortfolio(state);
                dispatch(updatePortfolio(state));
                setBgPosition(data.bannerOffset);
            } catch (error) {
                console.error(error)
            }
        };
        initPortfolio();
    }, []);

    const handleLinkClick = (url: string) => {
        navigate(url);
    };

    const { scrollY } = useScroll();

    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    });

    const saveMyPerf = async () => {
        if (performance.title === ""
            && performance.bannerOffset === 0
            && performance.banner.id === ""
            && performance.description === ""
            && performance.type === "")
            return false;
        if (!performance?.banner || !performance.banner?.id) {
            toast.warning(t("nobanner"));
            return true;
        } else if (!performance.type) {
            toast.warning(t("notype"));
            return true;
        } else if (!performance.title) {
            toast.warning(t("notitle"));
            return true;
        } else if (!portfolio || !portfolio.id) {
            toast.warning(t("noidPortfolio"));
            return true;
        } else if ((performance.priceType === "fixed" || performance.priceType === "from" || performance.priceType === "range") && performance.priceValue === 0) {
            toast.warning(t("noprice"));
            return true;
        } else if (performance.priceType === "range" && performance.priceCeil === 0) {
            toast.warning(t("noprice"));
            return true;
        }

        const datas = {
            banner: `/api/public/pictures/${performance?.banner?.id}`,
            title: performance.title,
            portfolio: `/api/public/portfolios/${portfolio.id}`,
            bannerOffset: performance.bannerOffset,
            ...(performance.description !== "" && { description: performance.description }),
            ...(performance.priceType !== "" && { priceType: performance.priceType }),
            ...((performance.priceType === "fixed" || performance.priceType === "from" || performance.priceType === "range") && performance.priceValue !== 0 && { priceValue: performance.priceValue }),
            ...(performance.priceType === "range" && performance.priceCeil !== 0 && { priceCeil: performance.priceCeil }),
            ...(performance.type !== "" && { type: `/api/service-types/${performance.type}` }),
            ...(performance.pictures.length > 0 && {
                pictures: performance.pictures.map((picture: any, index: number) => {
                    return {
                        picture: `/api/public/pictures/${picture.id}`,
                        index: index
                    }
                })
            }),
            ...(performance.galleries.length > 0 && {
                galleries: performance.galleries.map((gallery: any, index: number) => {
                    return {
                        gallery: `/api/public/galleries/${gallery.id}`,
                        index: index
                    }
                })
            }),
            ...(performance.keywords.length > 0 && {
                keywords: performance.keywords.map((tag, index: number) => {
                    return {
                        customLabel: tag.label,
                        index: index
                    }
                })
            })
        }

        const response = await ServiceAPI.create(datas);
        if (response.status === 200 || response.status === 201) {
            toast.success(t("successService"));
            const getAllServcies = await PortfolioAPI.getServicesDraft(portfolio.id);
            if (getAllServcies.status === 200 || getAllServcies.status === 201) {
                dispatch(updateServices(getAllServcies.body));
                dispatch(updateResetPerformance())
                return false
            } else {
                handleError(getAllServcies);
                return true
            }
        } else {
            handleError(response);
            return true;
        }
    }

    const saveMyEditPerf = async () => {
        if (performanceEdit.title === "")
            return false;
        const datas = {
            banner: `/api/public/pictures/${performanceEdit?.banner?.id}`,
            title: performanceEdit.title,
            portfolio: `/api/public/portfolios/${performanceEdit.portfolio.id}`,
            bannerOffset: performanceEdit?.bannerOffset ?? 0,
            ...(performanceEdit.description !== "" && { description: performanceEdit.description }),
            ...(performanceEdit.priceType !== "" && { priceType: performanceEdit.priceType }),
            ...((performanceEdit.priceType === "fixed" || performanceEdit.priceType === "from" || performanceEdit.priceType === "range") &&
                performanceEdit.priceValue !== 0 && { priceValue: performanceEdit.priceValue }),
            ...(performanceEdit.priceType === "range" && performanceEdit.priceCeil !== 0 && { priceCeil: performanceEdit.priceCeil }),
            ...(performanceEdit.type.enumValue !== "" && { type: `/api/service-types/${performanceEdit.type.enumValue}` }),
            ...(performanceEdit.pictures.length > 0 && {
                pictures: performanceEdit.pictures.map((picture: { id: string }, index: number) => {
                    return {
                        picture: `/api/public/pictures/${picture.id}`,
                        index: index,
                    };
                }),
            }),
            ...(performanceEdit.galleries.length > 0 && {
                galleries: performanceEdit.galleries.map((gallery: { id: string }, index: number) => {
                    return {
                        gallery: `/api/public/galleries/${gallery.id}`,
                        index: index,
                    };
                }),
            }),
            ...(performance.keywords.length > 0 && {
                keywords: performanceEdit.keywords.map((keyword: {
                    customLabel?: string;
                }, index: number) => {
                    return {
                        customLabel: keyword,
                        index: index,
                    };
                }),
            }),
        };
        const response = await ServiceAPI.edit(performanceEdit.id, datas);
        if (response.status === 200 || response.status === 201) {
            toast.success(t("successServiceEdit"));
            const getAllServcies = await PortfolioAPI.getServicesDraft(portfolio.id);
            if (getAllServcies.status === 200 || getAllServcies.status === 201) {
                dispatch(updateServices(getAllServcies.body));
                dispatch(updateResetPerformance())
                return false
            } else {
                handleError(getAllServcies);
                return true
            }
        } else {
            handleError(response);
            return true;
        }
    }


    const handleSubmitWithoutPublish = async () => {
        if (await saveMyPerf())
            return;
        if (await saveMyEditPerf())
            return;

        const mergedArray = [...portfolio.flash, ...portfolio.body, ...portfolio.lens];
        const filteredArray = mergedArray.filter((item: Setup) => item.brandLabel !== "" && item.modelLabel !== "");
        const data = {
            name: portfolio.name,
            description: portfolio.description,
            photoStyles: portfolio.photoStyles.map((style: { enumValue: string; frLabel: string; enLabel: string }) => `/api/photo-styles/${style.enumValue}`),
            regions: portfolio.regions.map((region: { id: string; code: string; frLabel: string; enLabel: string }) => `/api/regions/${region.id}`),
            locales: portfolio.locales.map((locale: { enumValue: string; label: string }) => `/api/locales/${locale.enumValue}`),
            socialNetworks: portfolio.socialNetworks.map((social: { url: string }) => ({ url: social.url })),
            banner: portfolio.backgroundForModif.id !== "" ? `/api/public/pictures/${portfolio.backgroundForModif.id}` : undefined,
            bannerOffset: bgPosition,
            setup: filteredArray,
            hideLocality: portfolio.hideLocality,
        };

        if (general?.me?.portfolio?.id) {
            const response = await PortfolioAPI.putPortfolio(general.me.portfolio.id, data);
            if (response.status === 200) {
                if (portfolio.pictureForModif) {
                    const formDataPicture = new FormData();
                    formDataPicture.append("image", portfolio.pictureForModif);
                    const resp = await PortfolioAPI.picture(portfolio.id, formDataPicture);
                    if (resp.status === 200 || resp.status === 201) {
                        toast.success(t("portfolioSuccess"));
                        setIsSaved(true);
                    } else handleError(resp);
                } else {
                    toast.success(t("portfolioSuccess"));
                    setIsSaved(true);
                }
            } else {
                handleError(response);
            }
        }
    };

    const handleSubmit = async () => {
        const mergedArray = [...portfolio.flash, ...portfolio.body, ...portfolio.lens];
        const filteredArray = mergedArray.filter((item: Setup) => item.brandLabel !== "" && item.modelLabel !== "");
        const data: {
            name: string;
            description: string;
            photoStyles: string[];
            regions: string[];
            locales: string[];
            socialNetworks: { url: string }[];
            banner?: string;
            bannerOffset: number;
            setup: Setup[];
            hideLocality?: boolean;
        } = {
            name: portfolio.name,
            description: portfolio.description,
            photoStyles: portfolio.photoStyles.map((style: { enumValue: string; frLabel: string; enLabel: string }) => `/api/photo-styles/${style.enumValue}`),
            regions: portfolio.regions.map((region: { id: string; code: string; frLabel: string; enLabel: string }) => `/api/regions/${region.id}`),
            locales: portfolio.locales.map((locale: { enumValue: string; label: string }) => `/api/locales/${locale.enumValue}`),
            socialNetworks: portfolio.socialNetworks.map((social: { url: string }) => ({ url: social.url })),
            banner: portfolio.backgroundForModif.id !== "" ? `/api/public/pictures/${portfolio.backgroundForModif.id}` : undefined,
            bannerOffset: bgPosition,
            setup: filteredArray,
            hideLocality: portfolio.hideLocality,
        };

        if (general?.me?.portfolio?.id) {
            const response = await PortfolioAPI.putPortfolio(general.me.portfolio.id, data);
            if (response.status === 200) {
                if (portfolio.pictureForModif) {
                    const formDataPicture = new FormData();
                    formDataPicture.append("image", portfolio.pictureForModif);
                    const resp = await PortfolioAPI.picture(portfolio.id, formDataPicture);
                    if (resp.status === 200 || resp.status === 201) {
                        toast.success(t("portfolioSuccess"));
                        const publishResp = await PortfolioAPI.publish(portfolio.id);
                        if (publishResp.status === 200 || publishResp.status === 201) {
                            setIsSaved(true);
                        } else handleError(publishResp);
                    } else handleError(resp);
                } else {
                    toast.success(t("portfolioSuccess"));
                    const publishResp = await PortfolioAPI.publish(portfolio.id);
                    if (publishResp.status === 200 || publishResp.status === 201) {
                        setIsSaved(true);
                    } else handleError(publishResp);
                }
            } else {
                handleError(response);
            }
        }
    };

    const handleReloadPictures = () => {
        setReloadPictures(new Date());
    }
    const { modalVisible, handleConfirmLeave, handleCancelLeave } = useNavigationBlocker(
        !isSaved && initialPortfolio !== null && !isDeepEqual(initialPortfolio, portfolio),
        navigate,
        (a: string, b: string) => b.startsWith("/portfolio/edit")
    );

    useEffect(() => {
        if (isSaved) {
            if (portfolio.path === "" && general.me) navigate(`/portfolio/${general.me.portfolio.id}/photos`);
            else navigate(`/${portfolio.path}/photos`);
        }
    }, [isSaved, portfolio]);

    return (
        <div className="min-h-full relative">
            <ConfirmLeaveModal
                isVisible={modalVisible}
                onConfirm={handleConfirmLeave}
                onCancel={handleCancelLeave}
            />
            <Loader loading={portfolio.loading} />
            <PictureResult
                overrides={{
                    pagetype: "portfolio",
                    portfolio: portfolio.id,
                }}
                reloadDate={reloadPictures}
            >
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <div className="flex flex-col h-full -mt-3 pb-[340px] md:pb-36">
                    <DesktopHeader
                        bgPosition={bgPosition}
                        setBgPosition={setBgPosition}
                        actualUrl={currentPage}
                        handleLinkClick={handleLinkClick}
                        handleSubmit={handleSubmit}
                        handleSubmitWithoutPublish={handleSubmitWithoutPublish}
                    />
                    <MobileHeader
                        bgPosition={bgPosition}
                        setBgPosition={setBgPosition}
                        actualUrl={currentPage}
                        handleLinkClick={handleLinkClick}
                        handleSubmit={handleSubmit}
                        searchBar={searchBar}
                        handleSubmitWithoutPublish={handleSubmitWithoutPublish}
                    />
                    <div className="flex-grow w-full bg-gray-50 rounded-b-lg md:rounded-b-none md:rounded-r-lg pb-20">
                        <div className="w-full max-w-7xl mx-auto">{!portfolio.loading && <Outlet context={[handleReloadPictures]} />}</div>
                    </div>
                </div>
            </PictureResult>
            <Footer />
        </div>
    );
}
