import { useTranslation } from "react-i18next";
import DoubleBar from "../../../../components/NavBar/DoubleBar";
import HeaderLight from "../../../../components/NavBar/HeaderLight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DoubleBarHeader from "../../../../components/NavBar/DoubleBarHeader";

import BackgroundDefault from "../../../../assets/images/elements/default-banner.png"
import { PictureType } from "../../../../components/Interfaces/PictureType";

export default function Header({ backgroundPicture }: Readonly<{ backgroundPicture: PictureType | null }>) {
    const { t } = useTranslation();

    return (
        <div className="relative h-5/6" style={{ height: "calc(80vh)" }}>
            <div
                className="h-[1128.96px]"
                style={{
                    backgroundImage: `url('${backgroundPicture?.publicPath ?? BackgroundDefault}')`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100vmax",
                    zIndex: -1,
                    position: "fixed",
                    top: 0,
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    pointerEvents: "none",
                    backgroundPositionX: "center",
                }}
            ></div>
            <HeaderLight />
            <div className="flex flex-col top-1/4 md:-translate-y-1/2 absolute md:top-1/2 w-full pt-6">
                <h1 data-entrance="from-top" className="text-white text-5xl text-center mb-6">
                    {t("slogan")}
                </h1>
                <h2 data-entrance="from-top" className="hidden md:flex text-white text-xl text-center mb-8 max-w-3xl mx-auto">
                    {t("slogan2")}
                </h2>
                <h2 data-entrance="from-top" className="flex md:hidden text-white text-xl text-center mb-8 max-w-3xl mx-auto">
                    {t("slogan2_small")}
                </h2>
                <div className="hidden md:flex">
                    <DoubleBar
                        amIOnHomePage={true}
                    />
                </div>
                <div className="flex md:hidden">
                    <DoubleBarHeader />
                </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 h-8 w-8 flex items-center justify-center rounded-full mx-auto animate-bounce z-[-1]">
                <FontAwesomeIcon icon="chevron-down" className="text-white text-2xl mx-auto" />
            </div>
            <div className="absolute bottom-0 right-4 md:right-[34px] flex items-end justify-center text-right">
                <p className="text-white text-sm mx-auto text-right font-bold" style={{ textShadow: "#000000BB 1px 0 10px" }}>{backgroundPicture?.title ?? ""}<br />{t("by")} {backgroundPicture?.portfolio?.name ?? ""}</p>
            </div>
        </div>
    );
}
