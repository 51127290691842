import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, unstable_useBlocker } from 'react-router-dom';
import ModalWrapper from '../components/Modals/ModalWrapper';
import { Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const isDeepEqual = (a: any, b: any) => {
    if (a === null || b === null || a === undefined || b === undefined) return a === b;
    if (a === b) return true;
    if (typeof a !== 'object' || typeof b !== 'object') return false;
    if (Object.keys(a).length !== Object.keys(b).length) return false;
    for (const key in a) {
        if (!(key in b) || !isDeepEqual(a[key], b[key])) return false;
    }
    return true;
}


export function useNavigationBlocker(hasUnsavedChanges: boolean, navigate: NavigateFunction, isSamePage: (a: string, b: string) => boolean) {
    const [modalVisible, setModalVisible] = useState(false);
    const [nextLocation, setNextLocation] = useState<string | null>(null);

    const handleConfirmLeave = useCallback(() => {
        setModalVisible(false);
        if (nextLocation) {
            navigate(nextLocation);
        }
    }, [nextLocation, navigate]);

    const handleCancelLeave = useCallback(() => {
        setModalVisible(false);
        setNextLocation(null);
    }, []);

    unstable_useBlocker(({ currentLocation, nextLocation }) => {
        if (isSamePage(currentLocation.pathname, nextLocation.pathname)) return false;
        if (modalVisible) return false;
        if (hasUnsavedChanges) {
            setNextLocation(nextLocation.pathname); // Store only the path
            setModalVisible(true);
            return true;
        }
        return false;
    });

    return { modalVisible, handleConfirmLeave, handleCancelLeave };
}

interface ConfirmLeaveModalProps {
    isVisible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

export function ConfirmLeaveModal({ isVisible, onConfirm, onCancel }: ConfirmLeaveModalProps) {
    const { t } = useTranslation();

    return (
        <ModalWrapper open={isVisible} setOpen={onCancel}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {t("unsaved_changes")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="times"
                    className="absolute top-4 right-4 text-gray-500 cursor-pointer"
                    onClick={onCancel}
                />
                <div className="mt-6">
                    <p className="text-lg text-center text-gray-500">
                        {t("unsaved_changes_message")}
                    </p>
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full border text-white bg-orange-500 hover:bg-orange-100 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={onConfirm}
                    >
                        {t("leave_edit")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full border bg-white hover:bg-gray-50 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={onCancel}
                    >
                        {t("stay_on_edit")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}

