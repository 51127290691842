import {
  CompatibleFilterList,
  FetchFiltersFunction,
  FilterList,
  FilterRenderConfiguration,
  FilterRenderer,
  FilterType,
  SortOption,
} from "../types/global";
import DropDown from "./DropDownFilter";
import { FilterConfiguration } from "./FilterConfiguration";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import DatePickerFilter from "./DatePickerFilter";
import MapPickerFilter from "./MapPickerFilter";
import { Option, Select } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import BooleanFilter from "./BooleanFilter";
import DropDownTitle from "./DropDownTitle";
import { Menu } from "@headlessui/react";

interface SortByProps {
  sortOptions: SortOption[];
  defaultSort?: string;
  setSort: (sort: string | undefined) => void;
  sort: string | undefined;
  left: boolean;
  mobile?: boolean;
}
export function SortBy(props: Readonly<SortByProps>) {
  const { t } = useTranslation();

  return (
    <div className={props.mobile ? "flex md:hidden" : "hidden md:flex"}>
      <DropDownTitle
        left={props.left}
        setSort={props.setSort}
        sort={props.sort}
        sortOptions={props.sortOptions}
        defaultSort={props.defaultSort}
      />
    </div>
  );
}

interface FiltersProps<
  FiltersType extends FilterList,
  CompatibleFiltersType extends CompatibleFilterList
> {
  filters: FilterConfiguration<FiltersType>;
  renderConfiguration: FilterRenderConfiguration<CompatibleFiltersType>;
  fetchFilters: FetchFiltersFunction<FiltersType, CompatibleFiltersType>;
  sortOptions: SortOption[];
  defaultSort?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  hideFilters: string[];
}

export default function Filters<
  FiltersType extends FilterList,
  CompatibleFiltersType extends CompatibleFilterList
>(props: Readonly<FiltersProps<FiltersType, CompatibleFiltersType>>) {
  const currentFilters = props.filters.getAdditionalFilters();
  const allFilters = props.filters.getAllFilters();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const availableFiltersValue: CompatibleFiltersType | undefined = useSelector(
    (state: any) => state.filters.compatibleFilters
  );

  const availableFilters =
    availableFiltersValue &&
    Object.entries(availableFiltersValue)
      .filter(
        ([key, value]) =>
          value &&
          !props.hideFilters.includes(key) &&
          (allFilters[key] || (!Array.isArray(value) || value.length >= 2)) &&
          props.renderConfiguration[key as keyof CompatibleFiltersType]
      )
      .sort(
        (a, b) =>
          Object.keys(props.renderConfiguration).indexOf(a[0]) -
          Object.keys(props.renderConfiguration).indexOf(b[0])
      );

  const fetchFilters = async () => {
    const result = await props.fetchFilters(props.filters);
    if (!result) {
      return;
    }
    if (props.filters.getAllFilters().galleries === true) {
      (result as any).galleries = true;
    }
    dispatch({ type: "SET_COMPATIBLE_FILTERS", payload: { filters: result } });
  };

  useEffect(() => {
    fetchFilters();
  }, [JSON.stringify(allFilters)]);
  const Renderers: {
    [key in FilterType]: FilterRenderer<CompatibleFiltersType>;
  } = {
    [FilterType.BOOLEAN]: (name, values, selected, configuration) => {
      const propertyValue = configuration.getValue(0 as any);

      return (
        <BooleanFilter
          title={`filters.${name as string}`}
          value={selected === propertyValue || propertyValue === "true" && selected === true ? true : undefined}
          onChange={(value) => {
            const key = configuration.getKey(0 as any);
            if (value === true) {
              props.filters.addFilter(key, propertyValue);
            } else {
              props.filters.removeFilter(key);
            }
          }}
        />
      );
    },
    [FilterType.DROPDOWN]: (name, values, selected, configuration) => {
      return (
        <DropDown
          title={`filters.${name as string}`}
          list={values.map((v) => ({
            key: configuration.getKey(v),
            value: configuration.getValue(v),
          }))}
          selected={selected as string}
          side="left"
          setSelected={(key: string) =>
            props.filters.addFilter(name as keyof FiltersType, key)
          }
          removeSelected={() =>
            props.filters.removeFilter(name as keyof FiltersType)
          }
        />
      );
    },
    [FilterType.DATEPICKER]: (name, values, selected, configuration) => {
      return (
        <DatePickerFilter
          title={`filters.${name as string}`}
          values={values}
          selected={selected as string}
          configuration={configuration}
          setSelected={(key: string) =>
            props.filters.addFilter(name as keyof FiltersType, key)
          }
          removeSelected={() =>
            props.filters.removeFilter(name as keyof FiltersType)
          }
        />
      );
    },
    [FilterType.MAPPICKER]: (name, values, selected, configuration) => {
      return (
        <MapPickerFilter
          title={`filters.${name as string}`}
          values={values}
          selected={selected as string}
          configuration={configuration}
          setSelected={(key: string) => {
            props.filters.addFilter(name as keyof FiltersType, key);
            props.filters.addFilter("country", "CHE");
          }}
          removeSelected={() => {
            props.filters.removeFilter(name as keyof FiltersType);
            props.filters.removeFilter("country");
          }}
        />
      );
    },
    [FilterType.CHIPS]: (name, values, selected, configuration) => {
      return (
        <>
          {values.map((v) => (
            <BooleanFilter
              key={configuration.getKey(v)}
              title={configuration.getValue(v)}
              value={selected === configuration.getKey(v) ? true : undefined}
              onChange={(value) => {
                if (value === true) {
                  props.filters.addFilter(name as keyof FiltersType, configuration.getKey(v));
                } else {
                  props.filters.removeFilter(name as keyof FiltersType);
                }
              }}
            />
          ))}
        </>
      );
    },
  };
  return (
    <div className="flex w-fit items-center gap-2">
      {(availableFilters && availableFilters.length > 0) ? (
        <div className="hidden md:flex flex-row flex-wrap gap-2 items-center">
          {props.open && props.filters.hasEnabledFilters(["query"]) && (
            <Menu>
              <div>
                <Menu.Button
                  className={`border-gray-600 px-4 py-1 rounded-full flex-row items-center gap-2 inline-flex bg-gray-300 hover:bg-gray-200 transition-all`}
                  onClick={() => props.filters.clearFilters()}
                >
                  {t("resetfilters")}
                </Menu.Button>
              </div>
            </Menu>
          )}
          {props.open &&
            availableFilters?.map(([key, value]) => {
              const config = props.renderConfiguration[key];
              if (!config) return null;
              const filterKey = config.type === FilterType.BOOLEAN ? config.getKey(0 as any) : key;
              return Renderers[config.type](
                key,
                value as CompatibleFiltersType[keyof CompatibleFiltersType],
                currentFilters[filterKey],
                props.renderConfiguration[key]
              );
            })}
          <BooleanFilter
            title={props.open ? "openfilters" : "closefilters"}
            value={
              props.filters.hasEnabledFilters(["query"]) ? true : undefined
            }
            onChange={(value) => {
              props.setOpen(!props.open);
            }}
          />
          
        </div>
      ) : ""}
      {(window.location.pathname !== "/popular" &&
        window.location.pathname !== "/recent" &&
        window.location.pathname !== "/popular-photographers" &&
        window.location.pathname !== "/recent-photographers") && (
          <SortBy
            sortOptions={props.sortOptions}
            defaultSort={props.defaultSort}
            setSort={(v) => props.filters.sortBy(v)}
            sort={props.filters.getSortBy()}
            left={!props.open}
          />
        )}
    </div>
  );
}
