import moment from "moment";
import { Link } from "react-router-dom";

export default function GaleryForPhoto({ id, name, img, offset, onClick }: { id: string, name: string; img: string, offset: number, onClick?: () => void }) {
    return (
        <Link className="w-full md:w-full mb-5" to={`/gallery/${id}`} onClick={onClick}>
            <div className="w-full md:w-full mx-auto rounded overflow-hidden shadow-lg h-full bg-white" key={name}>
                <div className="h-48 w-full bg-cover bg-center" style={{ backgroundImage: `url(${img})`, backgroundPositionY: `${100 - (offset ?? 0)}%`, }}></div>
                {/* <img className="w-full object-cover h-48" src={img} alt="Sunset in the mountains" /> */}
                <div className="px-6 py-4 flex flex-col items-center">
                    <div className="font-bold text-base text-center">{name}</div>
                </div>
            </div>
        </Link>
    )
}