import { ReactConfirmAlertProps, confirmAlert } from "react-confirm-alert";
import PicturesAPI from "../../../../services/API/Clients/PicturesAPI";
import { handleError } from "../../../../services/Errors/handleErrors";
import { Storage } from "../../../../services/storage";

const deleteAlert = (deleteRoutine: () => void, t: any): ReactConfirmAlertProps => ({
    title: t("delete"),
    message: t("delete_d"),
    buttons: [
        {
            label: t("keep"),
            className: "ezoom-alert-cancel",
        },
        {
            label: t("delete"),
            onClick: deleteRoutine,
        },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    overlayClassName: "ezoom-alert-overlay",
});

export const handleDelete = (actualId: string, t: any, navigate: any) => {
    confirmAlert(
        deleteAlert(async () => {
            const response = await PicturesAPI.deletePicture(actualId);
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                Storage.setDelete()
                navigate(0);
            } else {
                handleError(response);
            }
        }, t)
    );
};
