import { Chip, IconButton, Popover, PopoverContent, PopoverHandler, Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { GalleryInterface } from "../../../../components/Interfaces/GalleryInterface";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import { useEffect, useState } from "react";
import { Storage } from "../../../../services/storage";
import SharingBox from "../../../../components/Box/SharingBox";
import moment from "moment";
import FavoriteAPI from "../../../../services/API/Clients/FavoriteAPI";
import { toast } from "react-toastify";
import CardUserWithData from "../../../../components/Box/CardUserWithData";
import { PieChart } from "react-minimal-pie-chart";
import GalleryAPI from "../../../../services/API/Clients/GalleryAPI";
import { OpenGalleryReport } from "../../../../components/Modals/ModalGallerieReport";
import ModalSignInUp from "../../../../components/Modals/ModalSignInUp";
import { handleError } from "../../../../services/Errors/handleErrors";
import { Link } from "react-router-dom";

const myId = Storage.getId();

export default function GalleryHeader({ picturesLength, data, setOpenModal, fetchData }: Readonly<{ picturesLength: number, data: GalleryInterface, setOpenModal: any, fetchData: any }>) {
    const { t } = useTranslation();
    const [repartition, setRepartition] = useState<{
        users: number;
        photographers: number;
    }>({
        users: 0,
        photographers: 0,
    });
    const [openInvitation, setOpenInvitation] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!data.id || data.id === "") return
            const response = await GalleryAPI.getRepartition(data.id)
            if (response.status === 200) {
                setRepartition(response.body);
            } else {
                handleError(response);
            }
        }
        fetchData();
    }, [data]);

    const handleChangeFav = async () => {      
        if (myId) {  
            const datas = {
                gallery: `/api/public/galleries/${data.id}`,
                owner: `/api/users/${myId}`
            }
            if (!data.isFavorited) {
                const response = await FavoriteAPI.favGallerie(datas);
                if (response.status === 200 || response.status === 201)
                    toast.success(t("addToFavGallerie"))
                else
                    handleError(response);
            } else {
                const response = await FavoriteAPI.unFavGallerie(datas);
                if (response.status === 200 || response.status === 201 || response.status === 204)
                    toast.success(t("removeFromFavGallerie"))
                else
                    handleError(response);
            }
            fetchData();
        }
    }
    
    return (
        <>
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <div
                className="hidden md:flex relative bg-cover aspect-[16/6]"
                style={{
                    backgroundPositionY: `${100 - (data.bannerOffset ?? 0)}%`,
                    backgroundImage: `url(${(data.banner && data.banner.publicPath) ?? DefaultBannerEzoom})`,
                }}
            ></div>
            <div className="hidden md:flex flex-row justify-end gap-4 mt-2 px-4">
                {myId && myId === data.owner.id && (
                    <Link to={`/gallery/${data.id}/edit`}>
                        <button 
                            color="white"
                            className="hidden md:flex border border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black items-center gap-2 w-auto py-2 px-4"
                        >
                            <FontAwesomeIcon icon="pen" /> {t("modify")}
                        </button>
                    </Link>
                )}
                {myId && myId === data.owner.id && (
                    <button
                        onClick={() => setOpenModal(true)}
                        className="hidden md:flex border border-indian-500 hover:border-indian-500/50 text-indian-500 hover:text-indian-500/50 bg-white hover:bg-white/75 transition-all w-auto py-2 px-4 rounded-full items-center"
                    >
                        <FontAwesomeIcon icon="trash" className="mr-2" />
                        {t("delete")}
                    </button>
                )}
                {!data.private && data.published && (
                    <IconButton 
                        onClick={() => !Storage.getToken() ? setOpenInvitation(true) : handleChangeFav()} color={data.isFavorited ? "red" : "white"} 
                        className={`ring-1 ${data.isFavorited ? "ring-red-500/50 rounded-full hover:bg-red-400" : "ring-gray-400/50 rounded-full hover:bg-gray-50"} 
                        rounded-full transition-all`} 
                    >
                        <FontAwesomeIcon icon="heart" />
                    </IconButton>
                )}
                {data.public && (
                    <SharingBox />
                )}
                {myId && myId !== data.owner.id && (
                    <Popover placement="bottom-end">
                        <PopoverHandler>
                            <IconButton 
                                color="white" 
                                variant="outlined"
                                className="hidden md:flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                            >
                                <FontAwesomeIcon icon="ellipsis-vertical" />
                            </IconButton>
                        </PopoverHandler>
                        <PopoverContent className="p-0">
                            <button
                                className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full"
                                onClick={() => !Storage.getToken() ? setOpenInvitation(true) : data?.id && OpenGalleryReport(data.id, t)}
                            >
                                {t("report")}
                            </button>
                        </PopoverContent>
                    </Popover>
                )}
            </div>
            <div className="hidden md:flex flex-col items-center justify-center mt-4 px-4 gap-1">
                <Typography className="hidden md:flex justify-center text-5xl font-bold text-center font-sans" style={{ fontFamily: "Great Vibes" }}>
                    {data.title}
                </Typography>
                {(data.date || data?.place?.label) && (
                    <div className="flex gap-2 items-center text-center">
                        <span>{data.date && moment(data.date).format("DD.MM.YYYY")}</span>
                        <span>{data?.place?.label}</span>
                    </div>
                )}
                {(data.private || !data.published) && (
                    <div className="flex gap-2">
                        {data.private && (
                            <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                                {t("privateGallery")}
                            </div>
                        )}
                        {!data.published && (
                            <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                                {t("unpublishedGallery")}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="hidden md:flex flex-col md:flex-row justify-between items-center px-4 mt-4 max-w-7xl mx-auto w-full">
                <div className="flex flex-row justify-center md:justify-start gap-4 mt-4 md:mt-0 px-4 mx-auto w-full md:w-1/3">
                    <CardUserWithData data={data.portfolio} isVerified={data.owner.verified} />
                </div>
                <div className="flex flex-row flex-wrap justify-center gap-4 mt-4 md:mt-0 px-4 mx-auto w-full md:w-1/3">
                    <Chip
                        className="w-fit h-fit"
                        color="gray"
                        variant="ghost"
                        value={`${picturesLength} ${t("photos")}`}
                        icon={<FontAwesomeIcon icon="images" className="h-4 mt-0.5" />}
                    />
                    <Chip
                        className="w-fit h-fit"
                        color="gray"
                        variant="ghost"
                        value={`${data.viewCount} ${t("views")}`}
                        icon={<FontAwesomeIcon icon="eye" className="h-4 mt-0.5" />}
                    />
                    <Popover placement="bottom">
                        <PopoverHandler>
                            <Chip
                                className="w-fit h-fit cursor-pointer"
                                color="gray"
                                variant="ghost"
                                value={`${data.favoriteCount} ${t("favs")}`}
                                icon={<FontAwesomeIcon icon="heart" className="h-4 mt-0.5" />}
                            />
                        </PopoverHandler>
                        <PopoverContent className="z-40">
                            <div className="flex flex-row gap-2 items-center">
                                {repartition.users !== 0 || repartition.photographers !== 0 ? (
                                    <PieChart
                                        data={[
                                            { title: t("users"), value: repartition.users ?? 0, color: "rgb(239, 147, 53)" },
                                            { title: t("photographs"), value: repartition.photographers ?? 0, color: "rgb(98, 81, 153)" },
                                        ]}
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                ) : (
                                    <PieChart
                                        data={[{ title: t("users"), value: 100, color: "#d4d4d4" }]}
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                )}
                                <div className="flex flex-col gap-2">
                                    <div className="flex flex-row gap-2 items-center">
                                        <div className="w-4 h-4 bg-orange-400 rounded-full"></div>
                                        <span>{t("users")}</span>
                                        <span>{repartition.users ?? 0}%</span>
                                    </div>
                                    <div className="flex flex-row gap-2 items-center">
                                        <div className="w-4 h-4 bg-purple-400 rounded-full"></div>
                                        <span>{t("photographs")}</span>
                                        <span>{repartition.photographers ?? 0}%</span>
                                    </div>
                                </div>
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
                <div className="flex flex-rox md:flex-col justify-center md:justify-end md:items-end  mt-4 md:mt-0 px-4 mx-auto w-full md:w-1/3 text-gray-500 text-right gap-1">
                </div>
            </div>
        </>
    );
}
