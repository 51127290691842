import { toast } from "react-toastify";
import i18next from "i18next";

interface Response {
    status?: number;
    body?: {
        detail?: string;
        message?: string;
    };
}

const formatMessageForTranslation = (message: string) => {
    return message.replace(":", "");
};

export const handleError = (response: Response) => {
    if (response?.status) {
        switch (response.status) {
            case 1: 
                toast.error(i18next.t("internet_problem"));
                break;
            case 400:
            case 429:
            case 409:
                toast.warning(i18next.t(formatMessageForTranslation(response?.body?.detail ? response.body.detail : response?.body?.message ?? "error_ult")));
                break;
            case 401:
            case 403:
                (response && response?.body && response?.body?.detail && response?.body?.detail === "You must verify your email before you can connect.")
                    ? toast.error(i18next.t(response?.body?.detail)) 
                    : toast.error(i18next.t("no_perm"));
                break;
            case 404:
                toast.error(i18next.t(response?.body?.detail ? response?.body?.detail : "cant_access_infos"));
                break;
            case 500:
            default:
                toast.error(i18next.t("error_ult"));
                break;
        }
    } else {
        toast.error(i18next.t("error_ult"));
    }
};
