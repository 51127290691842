import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DefaultPictureEzoom from "../../../assets/images/elements/default-profile.png";
import GradientButton from "../../../components/Form/Button/GradientButton";
import PortfolioAPI from "../../../services/API/Clients/PortfolioAPI";
import { handleError } from "../../../services/Errors/handleErrors";
import { updateOpinion, updateOpinionNote } from "../../../services/redux/actions/formAction";
import ModalOpinion from "../componentCommun/ModalOpinion";
import BigComment from "./Opinion/BigComment";
import { TestimonialProps } from "../../../components/Interfaces/TestimonialProps";

export default function Opinion() {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const portfolio = useSelector((state: any) => state.form);
    const dispatch = useDispatch();

    const fetchData = async () => {
        const respOpinions = await PortfolioAPI.getOpinions(window.location.pathname.split("/")[2]);
        if (respOpinions.status === 200) {
            dispatch(updateOpinion(respOpinions.body));
            const respPortfolio = await PortfolioAPI.portfolio(portfolio.id);
            if (respPortfolio.status === 200) {
                dispatch(updateOpinionNote(respPortfolio.body?.avgNote ?? -1));
            } else {
                handleError(respPortfolio);
            }
        } else {
            handleError(respOpinions);
        }
    };
    
    const handleClose = (string?: string) => {
        setOpen(false);
    };

    return (
        <>
            <ModalOpinion open={open} setOpen={handleClose} handleReload={fetchData} />
            <div className="container px-0 mx-auto sm:px-5 my-4">
                {portfolio && portfolio.features && portfolio.features.actions && portfolio.features.actions.testimonial && (
                    <button className="float-right mb-4" onClick={() => setOpen(!open)}>
                        <GradientButton text={t("giveopinion")} />
                    </button>
                )}
                {portfolio && portfolio.opinions && portfolio.opinions.pagination.totalItems > 0 ? (
                    <div className="pt-12 px-3 md:px-0 md:pt-0">
                        {portfolio.opinions.data.length > 0 ? portfolio.opinions.data.map((testimonial: TestimonialProps) => {
                            return (
                                <>
                                    {testimonial?.answer ? (
                                        <BigComment
                                            key={testimonial.id}
                                            handleReloadTestimonials={fetchData}
                                            message={testimonial}
                                            answer={{
                                                content: testimonial?.answer,
                                            }}
                                        />
                                    ) : (
                                        <BigComment
                                            key={testimonial.id}
                                            handleReloadTestimonials={fetchData}
                                            message={testimonial}
                                        />
                                    )}
                                </>
                            )}
                        ) : (
                            <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                                <FontAwesomeIcon icon="comment-slash" className="mt-12 text-4xl text-gray-500/50 mb-4" />
                                <Typography className="text-base text-gray-700 mb-12 text-center">{t("nocomments")}</Typography>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                        <FontAwesomeIcon icon="comment-slash" className="mt-12 text-4xl text-gray-500/50 mb-4" />
                        <Typography className="text-base text-gray-700 mb-12 text-center">{t("nocomments")}</Typography>
                    </div>
                )}
            </div>
        </>
    );
}
