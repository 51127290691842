import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, RouterProvider, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import HomeIn from "./Home/LogIn/Home";
import Home from "./Home/LogOut/Home";
import ForgotPassword from "./Signin/ForgotPassword";
import Signin from "./Signin/Signin";
import EmailConfirmation from "./Signup/EmailConfirmation";
import PhoneConfirmation from "./Signup/PhoneConfirmation";
import Signup from "./Signup/Signup";

import Favorites from "./Favorites/Favorites";

import EditGallery from "./Gallery/Edit";
import Gallery from "./Gallery/Gallery";

import EditPortfolio from "./Portfolio/Edit";
import Portfolio from "./Portfolio/Portfolio";

import Photographers from "./Photographers/Photographers";

import Account from "./Account/Account";
import Stats from "./Stats/Stats";
import Subscription from "./Subscription/Subscription";

import Upload from "./Upload/Upload";

import Contact from "./Contact/Contact";

import Page404 from "./Bundle/404/Page404";
import Contracts from "./Contracts/Contracts";
import Download from "./Download/Download";
import About from "./Other/About";
import Avis from "./Other/Avis";
import Cgu from "./Other/CGU";
import Cgv from "./Other/CGV";
import Contacts from "./Other/Contact";
import Privacy from "./Other/Privacy";
import Galeries from "./Research/Galeries/Galeries";
import Popular from "./Research/Popular/Popular";
import PopularPhotographers from "./Research/Popular/PopularPhotographer";
import Recent from "./Research/Recent/Recent";
import Search from "./Research/Search/Search";

import Purchases from "./Purchases/Purchases";
import Sales from "./Sales/Sales";

import Aide from "./Other/Aide";
import CGUNewsletter from "./Other/CGUNewsletter";
import TmpPortfolio from "./Portfolio/TmpPortfolio";
import RecentPhotographers from "./Research/Recent/RecentPhotographers";
import Teaser from "./Teaser/Teaser";
import UnSubscribe from "./Teaser/UnSubscribe";

import ContractsAccount from "./Account/components/Contracts";
import Data from "./Account/components/Data";
import Notifications from "./Account/components/Notifications";
import PortfolioAccount from "./Account/components/Portfolio";
import SubscriptionAccount from "./Account/components/Purchases";
import Security from "./Account/components/Security";
import CartTransaction from "./Cart/CartTransaction";
import AboutEdit from "./Portfolio/componentsEdit/AboutEdit";
import PerformanceAdd from "./Portfolio/componentsEdit/Performances/PerformanceAdd";
import PerformanceDetailEdit from "./Portfolio/componentsEdit/Performances/PerformanceDetailEdit";
import PerformancesEdit from "./Portfolio/componentsEdit/PerformancesEdit";
import PhotosEdit from "./Portfolio/componentsEdit/PhotosEdit";
import AboutPortfolio from "./Portfolio/componentsPortfolio/About";
import ContactPortfolio from "./Portfolio/componentsPortfolio/Contact";
import Appointment from "./Portfolio/componentsPortfolio/Contact/Appointment";
import Inquiry from "./Portfolio/componentsPortfolio/Contact/Inquiry";
import Offers from "./Portfolio/componentsPortfolio/Contact/Offers";
import Opinion from "./Portfolio/componentsPortfolio/Opinion";
import Performances from "./Portfolio/componentsPortfolio/Performances";
import PerformanceDetail from "./Portfolio/componentsPortfolio/Performances/PerformanceDetail";
import Photos from "./Portfolio/componentsPortfolio/Photos";
import EndTransaction from "./Subscription/EndTransaction";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { PictureModalProvider } from "../providers/PictureModalProvider";
import { updateGeneral } from "../services/redux/actions/generalActions";
import { RootState } from "../services/redux/store";
import { Storage } from "../services/storage";
import PortfolioOr404 from "./Bundle/404/PortfolioOr404";
import ContractsEdit from "./Contracts/ContractEdit";
import { dataLoggedIn, dataUnlogged } from "./fetchData";
import IamPhotographer from "./Home/IamPhotographer/IamPhotographer";
import Tarifs from "./Other/Tarifs";
import DoubleAuth from "./Signin/DoubleAuth";
import RecoverPassword from "./Signin/RecoverPassword";
import SignupConfirm from "./Signup/SignupConfirm";
import { PictureEditProvider } from "../providers/PictureEditProvider";
import { CartProvider } from "../providers/CartProvider";
import ScrollToTop from "../components/Scroll/ScrollToTop";
import { FilterProvider } from "../components/SearchResult/provider/FilterProvider";
import EndTransactionStatic from "./Subscription/EndTransactionStatic";
import router from "./router";

const token = Storage.getToken();

export default function App() {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [dataFetched, setDataFetched] = React.useState<boolean>(true);
    const general = useSelector((state: RootState) => state.general);
    const dispatch = useDispatch();

    const fetchData = async (isLoggedin: boolean) => {
        try {
            if (!general.dataFetched) {
                const state = await (isLoggedin ? dataLoggedIn() : dataUnlogged());
                await dispatch(updateGeneral(state));
                setDataFetched(false);
                setLoading(false);
            } else {
                setDataFetched(false);
                setLoading(false);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchData(!!token);
    }, []);

    if (loading) {
        return <Loader loading={loading} />;
    }

    if (dataFetched) {
        return <Loader loading={dataFetched} />;
    }

    if (!general.dataFetched) {
        return <Loader loading={!general.dataFetched} />;
    }

    return (
        <RouterProvider router={router(token)} />
    );
}
