import { useTranslation } from "react-i18next";
import TitleHelper from "./components/TitleHelper";
import SelectForUpload from "./components/SelectForUpload";
import { useEffect, useState } from "react";
import CatsAPI from "../../../../services/API/Clients/CatsAPI";
import KeywordsAPI from "../../../../services/API/Clients/KeywordsAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TypeAPI from "../../../../services/API/Clients/TypeAPI";
import { Storage } from "../../../../services/storage";
import { handleError } from "../../../../services/Errors/handleErrors";

export default function Keywords({
    listTags,
    addTag,
    addTagToList,
    removeTag,
    optionCat,
    optionTyp,
    handleSelectCat,
    handleSelectTyp,
    tagInput,
    setTagInput,
    recommandation,
    setRecommandedKeywords
}: Readonly<{
    listTags: { value: string; label: string }[];
    addTag: any;
    addTagToList: any;
    removeTag: any;
    optionCat: string;
    optionTyp: string;
    handleSelectCat: any;
    handleSelectTyp: any;
    tagInput: string;
    setTagInput: any;
    recommandation: { value: string; label: string };
    setRecommandedKeywords: any;
}>) {
    const { t } = useTranslation();
    const [listKeywords, setListKeywords] = useState<{ value: string; label: string }[]>([]);
    const [listSuggestionsKeywords, setListSuggestionsKeywords] = useState<{ value: string; label: string }[]>([]);
    const [showFullList, setShowFullList] = useState(false);
    const myLang = Storage.getLanguage() ?? "fr";
    const [listCats, setListCats] = useState<{ value: string; label: string }[]>([]);
    const [listStyle, setListStyle] = useState<{ value: string; label: string }[]>([]);
    const [isFocused, setIsFocused] = useState(false);

    const getKeywords = async () => {
        if (optionCat === "") return;
        const response = await KeywordsAPI.keywordsCategory(optionCat);
        if (response.status === 200) {
            const data = response.body.map((key: any) => ({ value: key.enumValue, label: myLang === "fr" ? key.frLabel : key.enLabel }));
            if (listTags.length > 0) {
                const myData = data.filter((key: any) => !listTags.some((tag: any) => tag.value === key.value));
                setListKeywords(myData);
            } else {
                setListKeywords(data);
            }
        } else {
            handleError(response);  
        }
    };

    const filteringKeywords = () => {
        const data = listKeywords
        if (listTags.length > 0) {
            const myData = data.filter((key: any) => !listTags.some((tag: any) => tag.value === key.value));
            setListKeywords(myData);
        } else 
            setListKeywords(data);
    }

    const getCats = async () => {
        const response = await CatsAPI.publicCats();
        if (response.status === 200 || response.status === 201) {
            const data = response.body.map((cat: any) => ({ value: cat.enumValue, label: myLang === "fr" ? cat.frLabel : cat.enLabel }));
            const firstElement = { value: "", label: t("select_cat") };
            data.unshift(firstElement);
            setListCats(data);
        } else 
            handleError(response);
    };

    const getStyle = async () => {
        const response = await TypeAPI.types();
        if (response.status === 200 || response.status === 201) {
            const data = response.body.map((styl: any) => ({ value: styl.enumValue, label: myLang === "fr" ? styl.frLabel : styl.enLabel }));
            const firstElement = { value: "", label: t("select_style") };
            data.unshift(firstElement);
            setListStyle(data);
        } else
            handleError(response);
    };

    const handleNewRecommandation = async (event: any) => {
        setRecommandedKeywords({ value: "", label: "" })
        setTagInput(event.target.value);
        if (event.target.value === "") return
        if (optionCat === "") return
        const response = await KeywordsAPI.keywordsCategory(optionCat);
        if (response.status === 200 || response.status === 204 || response.status === 201) {
            const fullListe = response.body
            const filteredList = fullListe.filter((item:any) => {
                const frLabel = item.frLabel.toLowerCase();
                return frLabel.startsWith(event.target.value.toLowerCase());
            });
            if (filteredList[0] === undefined) return
            setRecommandedKeywords({
                value: filteredList[0].enumValue,
                label: myLang === "fr" ? filteredList[0].frLabel : filteredList[0].enLabel,
            });
        } else {
            setRecommandedKeywords({ value: "", label: "" })
            handleError(response);
        }
    }

    const getSuggestions = async () => {
        const userId = Storage.getId()
        const response = await KeywordsAPI.keywordsSuggestions(userId ?? "", optionCat);
        if (optionCat === "") return
        if (response.status === 200 || response.status === 204 || response.status === 201) {
            setListSuggestionsKeywords(
                response.body.data.map((key: any) => ({ value: key.customLabel, label: key.customLabel }))
            );
        } else {
            handleError(response);
        }
    }

    useEffect(() => {
        getCats();
        getStyle();
        getKeywords();
    }, []);

    useEffect(() => {
        getKeywords();
        getSuggestions();
        setRecommandedKeywords({ value: "", label: "" })
    }, [optionCat]);

    useEffect(() => {
        filteringKeywords();
    }, [listTags]);

    return (
        <div className=" pb-4 border-b border-gray-300 flex flex-col w-full mt-4">
            <TitleHelper title="keywords" infobulle={t("keywords_i")} />
            <div className="flex flex-col xl:flex-row gap-2">
                <SelectForUpload name="category" options={listCats} optionChoose={optionCat} handleChangeSelect={handleSelectCat} />
                <SelectForUpload name="typestyle" options={listStyle} optionChoose={optionTyp} handleChangeSelect={handleSelectTyp} />
            </div>
            <p className="text-sm text-gray-800 mt-2 text-justify mb-2">{t("keywords_d")}</p>
            <div className={`peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 border-t-transparent border focus:border-2 text-sm px-3 py-2.5 rounded-[7px] ${isFocused ? "border-orange-500 border-2" : "border-blue-gray-200"} bg-white relative`}>
                <input onKeyDown={(event: any) => addTag(event)} onFocus={() => setIsFocused(!isFocused)} onBlur={() => setIsFocused(!isFocused)} type="text" className={`w-full text-blue-gray-700 relative z-20 bg-transparent focus:outline-none focus:border-purple-500 focus:ring-0`} value={tagInput.toLowerCase()} onChange={handleNewRecommandation} />
                <p className="absolute top-2.5 left-3 text-blue-gray-200 z-1">{recommandation.label.toLowerCase()} {recommandation.label !== "" && <span className="border p-1 rounded">Tabulation</span>}</p>

                <label className={`flex w-full h-full select-none pointer-events-none absolute left-0 font-normal truncate ${isFocused ? "leading-tight text-orange-500 before:border-l-2 after:border-r-2 before:border-t-2 after:border-t-2 before:border-orange-500 after:border-orange-500 -mt-[2px]" : "leading-tight text-blue-gray-400"} transition-all -top-2 text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:border-t before:border-l before:pointer-events-none before:transition-all after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 after:rounded-tr-md after:border-t after:border-r after:pointer-events-none after:transition-all before:border-blue-gray-200 after:border-blue-gray-200`}>
                    {t("tags")}{" "}
                </label>
            </div>
            <div className="flex flex-row flex-wrap gap-2 mt-2">
                {listTags.map((tag: any, index: number) => (
                    <div className="bg-orange-200/30 text-orange-700 py-1 pl-3 p-2 rounded-full flex justify-between" key={tag.label}>
                        <p>{tag.label}</p>
                        <FontAwesomeIcon icon="circle-xmark" className="ml-2 cursor-pointer mt-1" onClick={() => removeTag(tag.label)} />
                    </div>
                ))}
            </div>
            {listSuggestionsKeywords.length > 0 && (
                <div
                    className={`flex flex-row flex-wrap gap-2 mt-2 h-auto items-center`}
                >
                    {listSuggestionsKeywords.map((tag: any, index: number) => (
                        <button key={tag.value} className="hover:opacity-90" onClick={() => addTagToList(tag.value, tag.label)}>
                            <div className="bg-blue-gray-200/30 text-blue-gray-700 py-1 pl-3 p-2 rounded-full flex justify-between" key={tag.label}>
                                <p>{tag.label}</p>
                            </div>
                        </button>
                    ))}
                </div>
            )}
            {listKeywords.length > 0 && (
                <div
                    className={`flex flex-row relative justify-between overflow-hidden ${
                        showFullList ? "h-auto pb-7 items-end" : "h-10 items-start"
                    } transition-all duration-700 ease-in-out`}
                >
                    <div className="flex flex-row flex-wrap gap-2 mt-2">
                        {listKeywords.map((tag: any, index: number) => (
                            <button key={tag.value} className="hover:opacity-90" onClick={() => addTagToList(tag.value, tag.label)}>
                                <div className="bg-blue-gray-200/30 text-blue-gray-700 py-1 pl-3 p-2 rounded-full flex justify-between" key={tag.label}>
                                    <p>{tag.label}</p>
                                </div>
                            </button>
                        ))}
                    </div>
                    <button
                        className="h-10 mt-0.5"
                        onClick={() => setShowFullList(!showFullList)}
                    >
                        <div className="bg-orange-200/30 text-orange-700 py-1 pl-3 p-2 rounded-full flex justify-between">
                            {showFullList ? <FontAwesomeIcon icon="chevron-up" /> : "..."}
                        </div>
                    </button>
                </div>
            )}
        </div>
    );
}
